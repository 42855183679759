.pcBreadcrumb {
	@include funcMarginVerHori(10, 0);

	.mgBreadcrumb {
		@include funcOverflowHidden();

		> ul {
			@include funcMarginAuto();
		
			> li {
				@include funcLineHeight(20);
				@include funcFontColor($dgFontColorLight);
				@include funcFloat(left);
			
				> span.emphasis {
					@include funcFontWeight(bold);
					@include funcFontColor($dgFontColorBase);
				}
			}
		}
	
		> ul.wide_wrapper {
			@include funcWidth($dgBoxWidthWide);
		}
	
		> ul.wrapper {
			@include funcWidth($dgBoxWidthBase);
		}
	}
}