
@mixin mlGlobalNavi($width, $size)
{
	@include funcMarginAuto();
	@include funcPaddingTop( 5);
	@include funcWidth($width);

	> ul {
		@include funcOverflowHidden();

		> li {
			@include funcFloat(left);
		}

		> li.separater {
			@include funcWidth($size);
			@include funcTextAlign(center);
		}
	}
}
.mgGlobalNavi {
	@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_navi.webp"), left, top);

	> div.wide_wrapper {
		@include mlGlobalNavi($dgBoxWidthWide, 50);
	}

	> div.wrapper {
		@include mlGlobalNavi($dgBoxWidthBase, 9);
	}
}