.mgDescript {
	@include funcOverflowHidden();
	@include funcBackgroundRepX(url("/assets/base/images/border_dashed.webp"), left, bottom);

	> div{
		@include funcMarginAuto();

		> h1 {
			@include funcFont($dgFontSizeBase - 2);
			@include funcFontColor(#888);
			@include funcLineHeight(33);

			> span.emphasis {
				@include mgFontBoldWithSize($dgFontSizeBase);
				@include funcFontColor($dgFontColorPitat);
			}
		}
	}

	> div.wrapper {
		@include funcWidth($dgBoxWidthBase);
	}

	> div.wrapper_wide {
		@include funcWidth($dgBoxWidthWide);

	}
}
