.mlCitySelect {
	@include mgContentBoxSelectCityLevel( 217);
	
	> div.select_area {
		
		> div.select_all {
			@include funcFont($dgFontSizeBase + 3);
		}
	}
}

.mlTownSelect {
	@include mgContentBoxSelectCityLevel( 217);
	
	> div.select_area {
		
		> p {
			@include funcPaddingLeft( 17);
			@include funcFont($dgFontSizeBase + 3);
			@include funcBackgroundNoRep(url("/assets/#{$theme}/images/icn_circle.webp"), left, 50%);
		}
	}
}

.mlCitySearch {
	
	> div.title {
		@include mgBlockSpace();
	}
}