

@mixin mgBlockSpace {
	@include funcMarginBottom( $dgBoxMarginBase);
}

@mixin mgTopBlockSpace()
{
	@include funcMarginBottom( $dgBoxMarginBase - 6);
}

@mixin mgVerticalImage()
{
	> img {
		@include funcVerticalAlign(middle);
	}
}

@mixin mgFontBoldWithSize($size)
{
	@include funcFont($size);
	@include funcFontWeight(bold);
}

@mixin mgContentBorder()
{
	@include funcBorder($dgBorderWidthBase, solid, $dgBorderColorBase);
}

@mixin mgSeparate($size, $style, $color)
{
	@include funcMarginAll( 0);
	@include funcHeight(0);
	@include funcBorderNone();
	@include funcBorderTop( $size, $style, $color);
}

@mixin mgPitatLineHeight()
{
	@include funcLineHeight($dgFontLineheightBase);
}

@mixin mgInputFontSize($size)
{

	> input[type="text"] {
		@include funcFont($size);
	}
}

@mixin mgTextareaFontSize($size)
{

	> textarea {
		@include funcFont($size);
	}
}

@mixin mgFormSelect()
{

	> select {
		@include funcFont($dgFontSizeBase + 4);
		@include funcVerticalAlign(initial);
	}
}

@mixin mgListFixWidth( $width)
{

	> ul {
		@include funcOverflowHidden();

		> li {
			@include funcWidth($width);
			@include funcFloat(left);
		}
	}
}
@mixin mgListFreeWidth( $size)
{

	> ul {
		@include funcOverflowHidden();

		> li {
			@include funcMarginRight( $size);
			@include funcFloat(left);

			> select {
				@include funcFont($dgFontSizeBase + 8);
				@include funcBorder(2, solid, #666);
				@include funcBorderRadius(4);
				@include funcPaddingVerHori(6, 4);
			}
		}
	}
}

@mixin mgListIcon($element, $txt_height, $size, $img_icon, $position_y, $position_x)
{
	@include funcLineHeight($txt_height);

	> #{$element} {
		@include funcPaddingLeft( $size);
		@include funcBackgroundNoRep($img_icon, $position_y, $position_x);
	}
}

@mixin mgListDot($element, $txt_height, $position_y)
{
	@include funcLineHeight($txt_height);

	> #{$element} {
		@include funcPaddingLeft( 8);
		@include funcBackgroundNoRep(url("/assets/base/images/icon/icn_circle_list_s.webp"), left, $position_y);
	}
}

@mixin mgListIconCircleM($txt_height)
{
	@include funcLineHeight($txt_height);

	> li {
		@include funcPaddingLeft( 12);
		@include funcBackgroundNoRep(url("/assets/base/images/icon/icn_circle_list_m.webp"), left, 45%);
	}

	> li.recommend_point_rent {
		@include funcPaddingLeft( 12);
		background-image: none;
	}
}

@mixin mgMapRouteIcon($txt_height)
{
	@include funcLineHeight($txt_height);

	>li {
		@include funcPadding(0, 10, 0, 25);
	}

	> li.data_address {
		@include funcBackgroundNoRep(url("/assets/base/images/icon/icn_map.webp"), left, top);
	}

	> li.data_route {
		@include funcBackgroundNoRep(url("/assets/base/images/icon/icn_train.webp"), left, top);
	}
}


@mixin mgRoundCorner($size, $weight, $style, $color)
{
	@include funcBorderRadius($size);
	@include funcBorder($weight, $style, $color);
}

@mixin mgRoundCornerBottom( $size, $weight, $style, $color)
{
	@include funcBorderRadiusBottom( $size);
	@include funcBorder($weight, $style, $color);
}

@mixin mgRoundCornerTop( $size, $weight, $style, $color)
{
	@include funcBorderRadiusTop( $size);
	@include funcBorder($weight, $style, $color);
}


@mixin mgPhotoImg()
{
	@include mgContentBorder();
}

@mixin mgPhotoFrameImg($size)
{
	@include funcPaddingAll( $size);
	@include mgContentBorder();
	@include funcBkColor($dgBoxColorBase);
}

@mixin mgPhotoColorFrameImg($white_size, $border_size, $border_color)
{
	@include funcPaddingAll( $white_size);
	@include funcBorder($border_size, solid, $border_color);
	@include funcBkColor($dgBoxColorBase);
}


@mixin mlTitleTopBgImg( $element, $bg_img)
{
	@include funcPadding(6, 0, 0, 15);
	@include funcOverflowHidden();
	@include funcBackgroundRepX($bg_img, left, top);

	> #{$element} {
		@include funcFont($dgFontSizeBase + 10);
		@include funcLineHeight(48);
		@include funcFontColor($dgFontColorInvert);

		> span.small_txt {
			@include funcFont($dgFontSizeBase + 10 - 4);
		}
	}
}

@mixin mlTitleBottomBgImg( $element, $bg_img)
{
	@include funcOverflowHidden();
	@include funcBackgroundRepX($bg_img, left, bottom);

	> #{$element} {
		@include funcPadding(7, 0, 0, 15);
		@include funcFont($dgFontSizeBase + 10);
		@include funcLineHeight(47);
		@include funcFontColor($dgFontColorInvert);

		> span.small_txt {
			@include funcFont($dgFontSizeBase + 10 - 4);
		}
	}
}

@mixin mlDetailTable($txt_height, $th_width, $td_width)
{
	@include funcBorder($dgBorderWidthBase, solid, #d6cbb2);
	@include funcLineHeight($txt_height);

	> tbody {

		> tr {

			> th {
				@include funcPaddingVerHori( 5, 10);
				@include funcWidth($th_width);
				@include funcFontWeight(bold);
				@include funcFontColor(#503d28);
				@include funcTextAlign(left);
				@include funcBorder($dgBorderWidthBase, solid, #d6cbb2);
				@include funcBkColor(#ede8de);
			}

			> td {
				@include funcPaddingVerHori( 5, 10);
				@include funcWidth($td_width);
				@include funcBorder($dgBorderWidthBase, solid, #d6cbb2);
			}
		}
	}
}


@mixin mgWrapOneColumn( $element, $theme)
{
	@include funcMarginAuto();
	@include funcWidth($dgBoxWidthBase);

	> div.title {
		@include mlTitleBottomBgImg( $element, url("/assets/#{$theme}/images/bg_title.webp"));
	}
}

@mixin mgWrapTwoColumn( $pos_main, $pos_side)
{
	@include funcMarginAuto();
	@include funcWidth($dgBoxWidthBase);

	> div.main_column {
		@include funcWidth($dgBoxWidthMain);
		@include funcFloat($pos_main);
	}

	> div.side_column {
		@include funcWidth($dgBoxWidthSide);
		@include funcFloat($pos_side);

		> div.block_space {
			@include funcMarginTop( $dgBoxMarginBase);
		}
	}
}

.mlSelect {
	@include mgWrapOneColumn( h2, $theme);
}

@mixin mlColumnMainRight()
{
	@include funcOverflowHidden();
	@include mgWrapTwoColumn( right, left);

	> div.main_column{

		> div.title {
			@include mlTitleTopBgImg( h2, url("/assets/#{$theme}/images/bg_title.webp"));
		}
	}
}


@mixin mgContentCircleBox( $size, $theme, $top_posi)
{
	@include mgBlockSpace();
	@include funcPaddingTop( $size);
	@include mgRoundCorner($dgBoxRadiusBase, $dgBorderWidthBase, solid, $dgBorderColorBase);
	@include funcBorderTopNone();
	@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_border_box_top.webp"), left, $top_posi);
}

@mixin mgContentBottomCircleBox( $size, $theme, $top_posi)
{
	@include mgBlockSpace();
	@include funcPaddingTop( $size);
	@include mgRoundCornerBottom( $dgBoxRadiusBase, $dgBorderWidthBase, solid, $dgBorderColorBase);
	@include funcBorderTopNone();
	@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_border_box_top.webp"), left, $top_posi);
}

@mixin mgContentTopCircleBox( $size, $theme, $top_posi)
{
	@include mgBlockSpace();
	@include funcPaddingTop( $size);
	@include mgRoundCornerTop( $dgBoxRadiusBase, $dgBorderWidthBase, solid, $dgBorderColorBase);
	@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_border_box_top.webp"), left, $top_posi);
}


@mixin mgContentCircleBoxTtlImg( $element, $theme, $bg_img)
{
	@include mgContentCircleBox( $dgBoxTopBorderSize, $theme, top);

	> #{$element} {
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcBackgroundRepX($bg_img, left, top);
	}
}

@mixin mgContentCircleBoxTtl( $element, $theme)
{
	@include mgContentCircleBox( $dgBoxTopBorderSize - 2, $theme, -2px);

	> #{$element} {
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
	}
}

@mixin mgContentCircleBoxTtlIcon( $element, $theme)
{
	@include mgContentCircleBoxTtl( $element, $theme);

	> #{$element} {
		@include funcMarginLeft(-4);
		@include funcPaddingTopSideBtm( 8, 0, 6);
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcLineHeight(31);

		> div.image_box {
			@include funcMarginRight( 10);
			@include funcFloat(left);
		}
	}
}

@mixin mgContentCircleBoxTtlIconMailForm( $element, $theme)
{
	@include mgContentCircleBoxTtl( $element, $theme);

	> #{$element} {
		@include funcMarginLeft(-4);
		@include funcPaddingTopSideBtm( 8, 0, 6);
		@include mgFontBoldWithSize($dgFontSizeBase + 8);
		@include funcLineHeight(31);

		> div.image_box {
			@include funcMarginRight( 10);
			@include funcFloat(left);
		}
	}
}

@mixin mgContentEdgeBoxTtl( $size, $theme, $top_posi)
{
	@include mgBlockSpace();

	@include funcPaddingTop( $size);

	@include funcBorder($dgBoxTopBorderSize, solid,$dgBorderColorBase);

	@include funcBorderTopNone();
	@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_border_box_top.webp"), left, $top_posi);
}


@mixin mgContentEdgeImgBoxTtl( $element, $bg_img)
{
	@include mgBlockSpace();

	> #{$element} {
		@include funcPadding(6, 0, 4, 40);
		@include funcMarginBottom( $dgBoxPaddingBase - 3);
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcLineHeight(33);
		@include funcBackgroundRepX($bg_img, left, top);
	}
}

@mixin mgSideContentCircleShadowBox( $theme, $contents)
{
	@include funcMarginBottom( $dgBoxMarginBase - 3);
	@include funcPaddingTop( 8);
	@include funcBackgroundNoRep(url("/assets/#{$theme}/images/bg_top_#{$contents}_box_top.webp"), left, top);

	> div.content_box_btm {
		@include funcPaddingBottom( 9);
		@include funcBackgroundNoRep(url("/assets/base/images/background/bg_top_#{$contents}_box_btm.webp"), left, bottom);

		> div.content_box_mid {
			@include funcPadding(0, 4, 0, 3);
			@include funcBackgroundRepY(url("/assets/base/images/background/bg_top_#{$contents}_box_mid.webp"), left, top);
		}
	}
}

@mixin mgSideContentGeneralBox( $theme)
{
	@include mgContentCircleBox(($dgBoxTopBorderSize / 2), $theme, -1px * ($dgBoxTopBorderSize / 2));
}

@mixin mgSideContentSideSpaceBox( $theme)
{
	@include funcPaddingVerHori( 0, $dgBoxPaddingBase);
	@include mgSideContentGeneralBox( $theme);
}

@mixin mgSideContentFrameBox( $element, $theme, $top_space, $frame_top, $frame_side)
{
	@include mgSideContentGeneralBox( $theme);

	> div.background_box {
		@include funcBorderRadius($dgBoxRadiusBase);
		@include funcBackground(url("/assets/base/images/background/bg_gray_stitch.webp"));

		> #{$element} {
			@include funcPaddingTopSideBtm( $top_space, $dgBoxPaddingBase, 0);
		}

		> div.photo_frame {
			@include funcMarginTopSideBtm( $frame_top, $frame_side, 0);
			@include funcPosition(absolute);
		}
	}
}


@mixin mgPlayScreen()
{
	@include funcPosition(relative);

	> a {

		> span.play_screen {
			@include funcPosition(absolute);
			@include funcPositionTop( 0);
			@include funcPositionLeft( 0);
		}
	}
}

@mixin mgSmallFont()
{
	@include funcMarginBottom( 5);
	@include mgFontBoldWithSize($dgFontSizeBase - 2);
	@include funcLineHeight($dgFontSizeBase);
	@include funcVerticalAlign(top);
	@include funcDisplay(block);
}

@mixin mgPhotoInfo($size, $space)
{
	@include funcOverflowHidden();

	> div.photo_box {
		@include funcFloat(left);
	}

	> div.detail_box {
		@include funcMarginLeft($size + $space);
	}
}

@mixin mgBoxPhotoWithInfo()
{

	> ul {
		@include funcMarginTop( $dgBoxPaddingBase);
		@include funcOverflowHidden();

		> li {
			@include funcMarginBottom( $dgBoxPaddingBase);
			@include mgPhotoInfo(76, 10);
		}
	}
}

@mixin mgBoxInfo()
{

	> ul {
		@include funcMarginTop( $dgBoxPaddingBase);
		@include funcOverflowHidden();

		> li {
			@include funcMarginBottom( $dgBoxPaddingBase);
			@include funcOverflowHidden();
			@include funcLineHeight($dgFontLineheightBase - 2);

			> span.date_data {
				@include mgSmallFont();
			}

			> a {
				@include funcLineHeight($dgFontLineheightBase - 2);
			}
		}
	}
}

.mgButton {
	@include funcPaddingBottom( $dgBoxPaddingBase);
	@include funcTextAlign(center);
}

@mixin mgPriceValue($size)
{

	> span.data_value {
		@include funcFont($size);
		@include funcFontWeight(bold);
		@include funcFontColor($dgFontColorAccent);
	}

	> span.data_unit {
		@include funcFontWeight(bold);
	}

	> span.add_data {
		@include funcFontWeight(bold);
		@include funcFontColor($dgFontColorAccent);
	}

	> span.small_data_value{
		@include funcFont(13);
		@include funcFontWeight(bold);
		@include funcFontColor($dgFontColorAccent);
	}

	> span.small_data_unit{
		@include funcFont(13);
	}
}

.mlSelectButtonBox {
	@include mgBlockSpace();
	@include funcPaddingTopSideBtm( $dgBoxPaddingBase - 3, 0, $dgBoxPaddingBase - 2);
	@include funcTextAlign(center);
	@include funcBackground(url("/assets/base/images/background/bg_bottom_btn_area.webp"));
}


.tmpFeatureImg {
	@include funcPosition(relative);
	@include mgBlockSpace();

	> div.label{
		@include funcPosition(absolute);
		@include funcPositionTop(95);
		@include funcPositionLeft(267);

		@include funcFont(14);
		@include funcFontColor(white);
	}

	> div.madori_label{
		@include funcPosition(absolute);
		@include funcPositionTop(87);
		@include funcPositionLeft(40);

		@include funcFont(14);
	}
}

@mixin mgMailFormTable($size)
{
	@include funcWidthPcnt(100);
	@include funcLineHeight(36);
	@include funcBorderTop( $dgBorderWidthBase, solid, $dgBorderColorBase);

	> tbody {

		> tr {
			@include funcBorderBottom( $dgBorderWidthBase, solid, $dgBorderColorBase);

			> th {
				@include funcPaddingTopSideBtm( 9, $dgBoxPaddingBase, 8);
				@include funcWidth($size);
				@include funcTextAlign(left);
				@include funcVerticalAlign(top);
				@include funcFontWeight(bold);
				@include funcBkColor(#eaeaea);
			}

			> td {
				@include funcPadding(9, 0, 9, 10);

				> ul {
					@include funcOverflowHidden();

					> li {
						@include funcFloat(left);

						> label {

							> select {
								@include funcFont($dgFontSizeBase + 8);
								@include funcBorder(2, solid, #666);
								@include funcBorderRadius(4);
								@include funcPaddingVerHori(6, 4);
							}
						}
					}
				}
			}
		}
	}
}

@mixin mgSelectList($size)
{
	> ul {
		@include funcPadding($dgBoxPaddingBase, 0, 0, $dgBoxPaddingBase);
		@include funcOverflowHidden();

		> li {
			@include funcMargin(0, $dgBoxPaddingBase, $dgBoxPaddingBase, 0);

			@include funcWidth($size);
			@include funcFloat(left);

			> input {
				@include funcPaddingAll( 5);
				@include funcMarginBottom(6)
			}

			> span.add_data {
				@include funcFont($dgFontSizeBase);

			}
		}
	}
}

@mixin mgContentBoxSelectCityLevel( $size)
{
	@include funcFont($dgFontSizeBase + 3);
	@include mgContentCircleBoxTtlIcon( h3, $theme);

	> div.select_area {
		@include funcPadding($dgBoxPaddingBase, 0, 0, $dgBoxPaddingBase);
		@include mgSelectList($size);
		@include funcOverflowHidden();
		@include funcBackgroundRepX(url("/assets/base/images/border_dashed.webp"), left, top);

		> div.select_more {
			@include funcMarginTopSideBtm( 0, $dgBoxPaddingBase, $dgBoxPaddingBase);
			@include funcPaddingLeft( 11);
			@include funcFloat(right);
		}
	}
}

.mlSearchOtherMethod {

	> dl {
		@include funcOverflowHidden();
		@include funcBackgroundNoRep(url("/assets/base/images/background/bg_search_other_method.webp"), left, top);
		@include mgListIcon(dd, 16 + 2, 16 + 6, url("/assets/#{$theme}/images/icn_arrow.webp"), left, top);
		@include funcPaddingBottom(20);

		> dt {
			@include funcPadding(14, 30, $dgBoxPaddingBase, $dgBoxPaddingBase);
			@include funcFloat(left);
		}

		> dd {
			@include funcMargin($dgBoxPaddingBase, 0, 0, 22);
			@include funcFont($dgFontSizeBase + 3);
			@include funcFloat(left);
		}
	}
}

@mixin mgSelectMap()
{
	@include funcPosition(relative);

	> a, span {
		@include funcPaddingLeft( 2);
		@include funcTextAlign(center);
		@include funcFontWeight(bold);
		@include funcWidth(53 - 2);
		@include funcHeight(25);
		@include funcFontColor($dgFontColorBase);
		@include funcLineHeight(25);
		@include funcDisplay(block);
		@include funcPosition(absolute);
		@include funcLetterSpacing(2);
	}

	> a:hover {
		@include funcTextDecoration(none);
		@include funcBackgroundNoRep(url("/assets/base/images/background/bg_map_on.webp"), center, 50%);
	}

	> span {
		@include funcFontColor(#bdbdbd);
	}
}

@mixin mgAddressAddData($place_width, $info_width)
{
	@include funcOverflowHidden();

	> ul.data_place {
		@include mgMapRouteIcon(19);
		@include funcWidth($place_width);
		@include funcFloat(left);

		> li.data_address {
			@include funcMarginBottom( 8);
		}
	}

	> ul.library_data_place {
		@include funcWidth(270);
	}

	> ul.add_info {
		@include mgListIconCircleM(19);
		@include funcMarginBottom( -7);
		@include funcWidth($info_width * 2 + $dgBoxPaddingBase * 2 + 12 * 2);

		@include funcFloat(left);
		@include funcOverflowHidden();

		> li {
			@include funcMargin(0, $dgBoxPaddingBase, 7, 0);
			@include funcWidth($info_width);
			@include funcFloat(left);
		}
	}

	> ul.library_add_info{
		@include mgListIconCircleM(19);
		@include funcMarginBottom( -7);
		@include funcWidth(360);
		@include funcFloat(left);
		@include funcOverflowHidden();

		> li {
			@include funcMargin(0, $dgBoxPaddingBase, 7, 0);
			@include funcWidth(150);
			@include funcFloat(left);
		}
	}
}

@mixin mgSelectArea($pref_width, $route_width, $st_width)
{

	> ul {

		> li {

			> ul {
				@include funcWidthPcnt(100);
				@include funcOverflowHidden();

				> li {
					@include funcFloat(left);
					@include mgFormSelect();

					> select {
						@include funcFont($dgFontSizeBase + 8);
						@include funcBorder(2, solid, #666);
						@include funcBorderRadius(4);
						@include funcPaddingVerHori(6, 4);
					}

					> select.must_select {
						@include funcFont($dgFontSizeBase + 8);
						@include funcBorder(2, solid, #ff762b);
						@include funcBorderRadius(4);
						@include funcPaddingVerHori(6, 4);
					}

					> select.reset {
						@include funcFont($dgFontSizeBase + 4);
						@include funcBorder(1, solid, #000);
						@include funcBorderRadius(0);
						@include funcPaddingVerHori(0, 0);
					}

					> input[type="text"] {
						@include funcFont($dgFontSizeBase + 8);
						@include funcWidth(503);
						@include funcPaddingVerHori(6, 4);
						@include funcBorder(2, solid, #666);
						@include funcBorderRadius(4);
						@include funcVerticalAlign(middle);
						box-shadow: 0px 2px 3px rgba(0, 0, 0, .1) inset;
					}

				}
			}

			> ul.select_station_area {

				> li {
					@include funcPaddingBottom( 10);
				}
			}

			> ul.desired_area {
				@include funcBorderTop( $dgBorderWidthBase, dotted, #d4d1ca);
				@include funcPaddingLeft(5);

				> li {
					@include funcMarginRight( 10);
					@include funcPaddingVerHori( 10, 0);
				}

				> li.select_desired {
					@include funcFontWeight(bold);
				}

				> li.pref_select {

					> select {
						@include funcWidth($pref_width);
					}
				}

				> li.route_select {

					> select {
						@include funcWidth($route_width);
					}
				}

				> li.station_select {

					> select {
						@include funcWidth($st_width);
					}
				}
			}
		}
	}
}

@mixin mgSelectShop($info_width, $li_width)
{

	> p {
		@include funcFontWeight(bold);
	}

	> table {
		@include funcWidthPcnt(100);
		@include funcBorderTop( 2, solid, #d4d1ca);

		> tbody {

			> tr {
				@include funcBorderBottom( 2, solid, #d4d1ca);

				> td.box_check {
					@include funcWidth(45);
					@include funcTextAlign(center);
					@include funcVerticalAlign(middle);
				}

				> td.box_info {
					@include funcWidth($info_width);

					> div.shop_name {
						@include funcPaddingTopSideBtm( 5, $dgBoxPaddingBase, 2);
						@include funcFontWeight(bold);
						@include funcBorderBottom( $dgBorderWidthBase, dotted, #d4d1ca);
						@include funcFont($dgFontSizeBase + 4);

						> span.txt_small {
							@include funcFont($dgFontSizeBase + 2);
							@include funcFontWeight(normal);
						}
					}

					> ul.data_info {
						@include funcPaddingTopSideBtm( 8, $dgBoxPaddingBase, 6);
						@include funcFont($dgFontSizeBase + 2);
						@include mlResultDataInfo($li_width);
						@include funcLineHeight(21);
					}
				}
			}
		}
	}
}


@mixin mlTopMainHeader()
{
	@include funcMarginBottom( 10);
	@include funcLineHeight($dgFontSizeBase);
	@include funcFont($dgFontSizeBase - 2);
	@include funcOverflowHidden();
	@include mgListFreeWidth( $dgBoxPaddingBase);

	> h1 {
		@include funcFloat(left);
	}

	> ul.top_navi {
		@include funcFloat(right);
		@include mgListIcon(li, $dgFontSizeBase, 10, url("/assets/base/images/arrow/icn_decre.webp"), left, 50%);
	}
}

@mixin mlTopMainBanaSlicer()
{
	@include funcFloat(left);
}

@mixin mlTopMainSearchCode()
{
	@include funcPadding(13, 10, 10, 8);
	@include funcWidth(213);
	@include funcFloat(right);
	@include funcBackgroundNoRep(url("/assets/top/images/bg_top_banner_mini.webp"), left, top);

	> form {

		> div.select_search {
			@include funcPadding(8, 0, 4, 72);
			@include funcBackgroundNoRep(url("/assets/top/images/bg_code_search.webp"), left, top);
			@include mgListFixWidth( 65);

			> ul {
				@include funcMargin(3, 0, 0, 7);

				> li {

					> label {
						@include mgVerticalImage();

						> input[type="radio"] {
							@include funcVerticalAlign(middle);
						}
					}
				}
			}
		}

		> div.input_code {
			@include mgInputFontSize($dgFontSizeBase + 2);

			> input[type="text"] {
				@include funcWidth(160);
				@include funcHeight(23);
				@include funcFloat(left);
				@include funcBorder($dgBorderWidthBase, solid, #999);
				@include funcBorderRightNone();
				@include funcBorderRadiusLeft( $dgBoxRadiusSmall);
			}
		}
	}
}

@mixin mlTopMainDeco()
{
	@include funcPosition(absolute);
	@include funcPositionBottom( 10);
	@include funcPositionRight( 15);
}

@mixin mlButtonSlidePositionLeft()
{
	@include funcPosition(absolute);
	@include funcPositionTop( 50);
	@include funcPositionLeft(0);
}
@mixin mlButtonSlidePositionRight()
{
	@include funcPosition(absolute);
	@include funcPositionTop( 50);
	@include funcPositionRight(0);
}

@mixin mlTopMainSlide()
{
	@include mgTopBlockSpace();
	@include funcPadding(2, 3, 5, 2);
	@include funcBackgroundNoRep(url("/assets/top/images/bg_top_index_main.webp"), left, top);

	> div.main_screen {
		@include funcPosition(relative);

		> div.btn_backward {
			@include mlButtonSlidePositionLeft();
		}

		> div.btn_forward {
			@include mlButtonSlidePositionRight();
		}

		> div.slide_show {
			@include funcMarginAll( 7);

			> div.btn_switch {
				@include funcPosition(absolute);
				@include funcPositionBottom( 0);
				@include funcPositionRight( 6);
			}
		}
	}

	> div.top_navigation {
		@include mgListFixWidth( 235 - 7 - 10);

		> ul {
			@include funcPadding(7, 0, 7, 6);

			> li {
				@include funcPadding(0, 10, 5, 7);
				@include funcTextAlign(center);
				@include funcFont($dgFontSizeBase + 2);
				@include funcFontWeight(bold);

				> ul {
					@include funcPadding(3, 0, 0, 2);
					@include funcOverflowHidden();

					> li {
						@include funcPaddingLeft( 10);
						@include funcFloat(left);
						@include funcTextAlign(left);
						@include funcLineHeight(42);
					}

					li.li_spacer {
						@include funcMarginRight( 13);
					}

					li.fix_width {
						@include funcWidth(235 - 7 - 10 - 10);
					}

					li.txt_small {
						@include funcFont($dgFontSizeBase + 1);
						@include mgVerticalImage();
					}
				}
			}

			> li.rent_navi {

				> ul {

					> li {
						@include funcBackgroundNoRep(url("/assets/top/images/icn_arrow_rent.webp"), left, 16px);
					}

					> li.txt_small {

						> a {
							@include funcFontColor(#c60101);
						}
					}
				}
			}

			> li.sale_navi {

				> ul {

					> li {
						@include funcBackgroundNoRep(url("/assets/top/images/icn_arrow_sale.webp"), left, 16px);
					}

					> li.txt_small {

						> a {
							@include funcFontColor(#038760);
						}
					}
				}
			}

			> li.lentsell_navi {

				> ul {

					> li {
						@include funcBackgroundNoRep(url("/assets/top/images/icn_arrow_lentsell.webp"), left, 16px);
					}
				}
			}
		}
	}
}

@mixin mlTopTitle()
{
	@include funcMarginAuto();
	@include funcPaddingTop( 5);
	@include funcMaxWidth(($dgBoxWidthSide + 6) * 2 + $dgBoxWidthMain + 5 + ($dgBoxMarginBase - 3) * 2);
	@include funcMinWidth($dgBoxWidthSide + 6 + $dgBoxWidthMain + 5 + $dgBoxMarginBase - 3);
	@include funcLineHeight(50);
	@include funcOverflowHidden();
	@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_top_title.webp"), left, top);

	> h2 {
		@include funcMarginLeft(20);
		@include funcFont($dgFontSizeBase + 10);
		@include funcFloat(left);

		> span.small_txt {
			@include funcFont($dgFontSizeBase + 2);
		}
	}

	> p {
		@include funcMarginRight( 20);
		@include funcFont($dgFontSizeBase - 2);
		@include funcFloat(right);

		> span.highlight_txt {
			@include mgFontBoldWithSize($dgFontSizeBase);
			@include funcFontColor($dgFontColorPitat);
		}
	}
}

@mixin mlTopMainVariouseFeature()
{
	@include funcPadding(5, 4, 4, 3);
	@include funcWidth(470);
	@include funcHeight(300);
	@include funcFloat(left);
	@include funcPosition(relative);
	@include funcBackgroundNoRep(url("/assets/#{$theme}/images/bg_top_main_feature.webp"), left, top);

	> div.main_screen {
		@include funcMarginTopSideBtm( 5, 5, 0);
	}

	> ul {
		@include funcPosition(absolute);
		@include funcPositionBottom( 8);
		@include funcPositionLeft( 8);
		@include funcOverflowHidden();

		> li {
			@include funcMarginRight( 2);
			@include funcTextAlign(center);
			@include funcFloat(left);
		}
	}
}

@mixin mlTopMainYahooAuction()
{
	@include funcMarginRight( $dgBoxMarginBase - 7);
	@include funcPadding(5, 5, 0, 3);
	@include funcWidth(231 - 3 - 5);
	@include funcHeight(148 - 5);
	@include funcFloat(left);
	@include funcTextAlign(center);
	@include funcOverflowHidden();
	@include funcBackgroundNoRep(url("/assets/sell/images/bg_top_main_small_box.webp"), left, top);

	> div.title {
		@include funcMarginTopSideBtm( 5, 5, 0);
	}

	> ul {
		@include funcMarginTopSideBtm( 0, 5, 5);
		@include funcBackgroundNoRep(url("/assets/sell/images/bg_yahoo_auction.webp"), left, top);

		> li {
			@include funcPadding(2, 0, 0, 25);
			@include funcTextAlign(left);
			@include funcLineHeight(37);
		}
	}
}

@mixin mlTopMainMarketData()
{
	@include mgSideContentCircleShadowBox( $theme, main);

	> div.content_box_btm {

		> div.content_box_mid {

			> div.title {
				@include funcPaddingTopSideBtm( $dgBoxPaddingBase, $dgBoxPaddingBase, 0);
			}

			> div.market_data {
				@include funcPaddingTopSideBtm( $dgBoxPaddingBase, $dgBoxPaddingBase, 10);

				> p {
					@include funcMarginBottom( 10);
					@include funcPaddingLeft( 15);
					@include mgFontBoldWithSize($dgFontSizeBase + 2);
					@include funcBackgroundNoRep(url("/assets/#{$theme}/images/icn_circle.webp"), left, 50%);
				}

				> ul {
					@include funcLineHeight(16);
					@include funcOverflowHidden();
					@include funcBorderBottom( $dgBorderWidthBase, dotted, $dgBorderColorBase);

					> li.date_data {
						@include funcPaddingVerHori( 7, 0);
						@include funcWidth(60);
						@include mgFontBoldWithSize($dgFontSizeBase - 2);
						@include funcFloat(left);
						@include funcBorderTop( $dgBorderWidthBase, dotted, $dgBorderColorBase);
					}

					> li.market_data_url {
						@include funcPaddingVerHori( 7, 0);
						@include funcMarginLeft(60);
						@include funcBorderTop( $dgBorderWidthBase, dotted, $dgBorderColorBase);
					}
				}
			}
		}
	}
}

@mixin mlTopMainAssess($size)
{
	@include mgTopBlockSpace();
	@include funcPosition(relative);

	> a {
		@include funcPosition(absolute);
		@include funcPositionLeft($size);
		@include funcPositionBottom( 32);
	}
}

@mixin mgTop()
{

	> div.main_contents {
		@include funcMarginAuto();
		@include funcPaddingTop( $dgBoxMarginBase - 4);
		@include funcMaxWidth(($dgBoxWidthSide + 6) * 2 + $dgBoxWidthMain + 5 + ($dgBoxMarginBase - 3) * 2);
		@include funcMinWidth($dgBoxWidthSide + 6 + $dgBoxWidthMain + 5 + $dgBoxMarginBase - 3);
		@include funcOverflowHidden();

		> div.mlMain {
			@include funcMarginLeft($dgBoxMarginBase - 3);
			@include funcWidth($dgBoxWidthMain + 5);
			@include funcFloat(left);

			> div.top_main_content_block {
				@include mgTopBlockSpace();
				@include funcOverflowHidden();

				> div.pitat_library {
					@include funcFloat(right);
				}
			}

			> div.top_main_content_wrapper {
				@include mgTopBlockSpace();
				@include funcHeight(148);
				@include funcPosition(relative);

				> div.bana_machipita {
					@include funcPosition(absolute);
					@include funcPositionTop( 0);
					@include funcPositionLeft( -10);
				}
			}

			> div {

				> div.position_left {
					@include funcFloat(left);
				}

				> div.position_right {
					@include funcFloat(right);
				}

				> div.top_main_content_small {
					@include funcWidth(231);

					> div.bana_above {
						@include funcMarginBottom( 14);
					}
				}

				> div.bana_sell_flow{
					@include funcPaddingLeft(13);
				}

				> div.decoration_box {
					@include mlTopMainDeco();
				}
			}
		}
	}

	> div.main_contents_short {
		@include funcWidth($dgBoxWidthSide + 6 + $dgBoxWidthMain + 5 + $dgBoxMarginBase - 3);
	}
}

.mlTopIndex {
	@include mgTop();
	@include funcBackgroundRepX(url("/assets/top/images/bg_top_index.webp"), left 20px, top);

	> div.main_contents {


		> div.top_header {
			@include mlTopMainHeader();
		}

		> div.mlMain {

			> div.top_main_content_slide {
				@include mlTopMainSlide();
			}

			> div {

				> div.bana_concierge {
					@include funcMarginRight( 14);
					@include funcFloat(left);
				}

				> div.bana_mcatalog {
					@include funcFloat(left);
				}

				> div.bana_gakusei{
					@include funcFloat(right);
				}

				> div.search_code {
					@include mlTopMainSearchCode();
				}
			}
		}
	}
}



.mlTop {
	@include mgTop();
	@include funcBackgroundRepX(url("/assets/base/images/background/bg_top_main.webp"), left, top 55px);

	> div.main_title {
		@include mlTopTitle();
	}

	> div.main_title_short {
		@include funcWidth($dgBoxWidthSide + 6 + $dgBoxWidthMain + 5 + $dgBoxMarginBase - 3);
	}

	> div.main_contents {

		> div.mlMain {

			> div.bana_customer_requested {
				@include mgTopBlockSpace();
			}

			> div {

				> div.various_feature {
					@include mlTopMainVariouseFeature();
				}

				> div.yahoo_auction {
					@include mlTopMainYahooAuction();
				}

				> div.bana_double_assess {
					@include funcFloat(left);
				}

				> div.bana_how_to_sell {
					@include funcFloat(right);
				}

				> div.bana_item {
					@include funcMarginRight( 14);
					@include funcFloat(left);
				}

			}

			> div.top_main_assess_sell {
				@include mlTopMainAssess(75);
			}

			> div.top_main_assess_rentowner {
				@include mlTopMainAssess(75);
			}

			> div.top_main_market {
				@include mlTopMainMarketData();
			}
		}
	}
}


@mixin mlTopSearchTab($size, $sort)
{

	> a {
		@include funcWidth($size);
		@include funcBackgroundNoRep(url("/assets/#{$theme}/images/tab_top_main_#{$sort}.webp"), left, top);
	}

	> a:hover {
		@include funcBackgroundNoRep(url("/assets/#{$theme}/images/tab_top_main_#{$sort}_on.webp"), left, top);
	}

	> a.active {
		@include funcBackgroundNoRep(url("/assets/#{$theme}/images/tab_top_main_#{$sort}_crnt.webp"), left, top);
	}
}

@mixin mlTopSearchForm()
{
	@include funcPadding($dgBoxPaddingBase, 0, 0, $dgBoxPaddingBase);

	> form {

		> input[type="text"] {
			@include funcFont($dgFontSizeBase + 1);
		}

		> div.selected_submit {
			@include funcMarginLeft(-$dgBoxPaddingBase);
			@include funcPaddingTopSideBtm( 10, 0, 5);
			@include funcTextAlign(center);
			@include funcBorderRadiusBottom( $dgBoxRadiusBase);
			@include funcBackgroundNoRep(url("/assets/base/images/background/bg_selected_submit.webp"), center, bottom);
		}
	}
}

.mlSearchNavi {
	@include mgTopBlockSpace();
	@include funcPadding(0, 4, 4, 2);
	@include funcBackgroundNoRep(url("/assets/#{$theme}/images/bg_top_main_base.webp"), left, bottom);

	> div.search_navi_tab {
		@include funcHeight(63);
		@include funcOverflowHidden();

		> ul {
			@include funcMarginLeft(10);
			@include funcOverflowHidden();

			> li {
				@include funcMarginLeft(5);
				@include funcFont($dgFontSizeBase + 4);
				@include funcFloat(left);
				@include funcTextAlign(center);

				> a {
					@include funcPadding(4, 4, 0, 3);
					@include funcFontColor($dgFontColorHalf);
					@include funcLineHeight(42);
					@include funcDisplay(block);
				}

				> a.active {
					@include funcFontColor($dgFontColorBase);
					@include funcLineHeight(49);
				}
			}

			> li.rent_tab {
				@include mlTopSearchTab(111-7, rent);
			}

			> li.shop_tab {
				@include mlTopSearchTab(135-7, shop);
			}

			> li.parking_tab {
				@include mlTopSearchTab(90-7, parking);
			}

			> li.elderly_tab {
				@include mlTopSearchTab(127-7, elderly);
			}

			> li.mansion_tab {
				@include mlTopSearchTab(111-7, mansion);
			}

			> li.house_tab {
				@include mlTopSearchTab(108-7, house);
			}

			> li.land_tab {
				@include mlTopSearchTab(75-7, land);
			}

			> li.invest_tab {
				@include mlTopSearchTab(127-7, invest);
			}

			> li.business_tab {
				@include mlTopSearchTab(126-7, business);
			}
		}
	}

	> div.search_navi_content {
		@include funcPaddingTopSideBtm( 0, $dgBoxPaddingBase, $dgBoxPaddingBase);
		@include funcOverflowHidden();

		> div.search_navi_side {
			@include funcFloat(left);

			> ul {

				> li {
					@include funcMarginBottom( 10);
				}
			}
		}

		> div.search_area {
			@include funcWidth(465);
			@include funcHeight(364);
			@include funcMarginLeft(217);
			@include mgRoundCorner($dgBoxRadiusBase, $dgBorderWidthBase, solid, $dgBorderColorBase);

			> div.search_ensen {
				@include funcWidth(465);
				@include funcHeight(364);
				@include mgSelectMap();
				@include funcBkColor(#D5F6FE);
				@include funcOverflowHidden();
			}

			> div.search_city {
				@include funcWidth(465);
				@include funcHeight(364);
				@include mgSelectMap();
				@include funcBkColor(#FBF2E2);
				@include funcOverflowHidden();
			}

			> div.search_map {
				@include funcWidth(465);
				@include funcHeight(364);
				@include mgSelectMap();
				@include funcBkColor(#fbebef);
				@include funcOverflowHidden();
			}

			> div.search_commuter {
				@include mlTopSearchForm();

				> form {

					> div.form_content {
						@include funcHeight(270);
						@include funcLineHeight(30);
						@include funcFont($dgFontSizeBase + 1);
						@include funcBackgroundNoRep(url("/assets/base/images/img_deco_train.webp"), 280px, bottom);

						> div {
							@include funcMarginRight( $dgBoxPaddingBase);
							@include funcPaddingVerHori( $dgBoxPaddingBase, 0);

							> input[type="text"] {
								@include funcMarginRight( 5);
								@include funcWidth(265);
								@include funcFontColor($dgFontColorHalf);
								@include funcFont($dgFontSizeBase + 1);
							}
						}

						> div.entry_field2 {
							@include funcPosition(relative);
							@include funcBorderTop( $dgBorderWidthBase, solid, $dgBorderColorBase);
							@include funcBorderBottom( $dgBorderWidthBase, solid, $dgBorderColorBase);

							> div.selected_reset {
								@include funcPaddingAll( $dgBoxPaddingBase);
								@include funcLineHeight(0);
								@include funcPosition(absolute);
								@include funcPositionTop( 0);
								@include funcPositionRight( 0);
								@include funcBackground($dgBgImageCross);
							}
						}
					}
				}
			}
			> div.search_school {
				@include mlTopSearchForm();

				> form {

					> div.form_content {
						@include funcHeight(270);
						@include funcLineHeight(30);
						@include funcFont($dgFontSizeBase + 1);
						@include funcBackgroundNoRep(url("/assets/base/images/img_deco_school.webp"), 330px, bottom);

						> div {
							@include funcMarginRight( $dgBoxPaddingBase);
							@include funcPaddingVerHori( 10, 0);

							> input[type="text"] {
								@include funcMargin(10, 0, 0, 0);
								@include funcWidth(265);
								@include funcFontColor($dgFontColorHalf);
								@include funcFont($dgFontSizeBase + 1);
							}

							> select{
								@include funcMargin(10, 0, 0, 0);
							}
						}

						> div.entry_field2 {
							@include funcPosition(relative);
							@include funcBorderTop( $dgBorderWidthBase, solid, $dgBorderColorBase);
							@include funcBorderBottom( $dgBorderWidthBase, solid, $dgBorderColorBase);

							> div.selected_reset {
								@include funcPaddingAll( $dgBoxPaddingBase);
								@include funcLineHeight(0);
								@include funcPosition(absolute);
								@include funcPositionTop( 0);
								@include funcPositionRight( 0);
								@include funcBackground($dgBgImageCross);
							}
						}

						> div.heads_up {
							@include funcFont(11);
							@include funcLineHeight(16);
							@include funcFontColor(red);
						}
					}
				}
			}
		}
	}
}


div.itemlink_box {
	@include funcOverflowHidden();
	@include mgRoundCorner(5, 0, solid, $dgBorderColorBase);
	@include funcMarginBottom(5);
	@include funcPaddingVerHori( 5, 5);

	@include funcBkColor(#fafafa);

	> h3.title {
		> span{
			@include funcFont(13);
			@include funcFontWeight(bold);
			@include funcLineHeight(20);
		}
	}

	> ul.itemlink_list {
		@include funcOverflowHidden();
		@include funcLineHeight(20);

		> li {
			@include funcFloat(left);
			@include funcMarginVerHori(0,5);

			> div {
				@include funcFloat(left);
			}

			> ul {
				> li{
					@include funcFloat(left);
				}
			}
		}
	}

	> ul.seo_ensen_list {
		@include funcLineHeight(20);

		> li {
			@include funcOverflowHidden();
			@include funcMarginVerHori(0,10);

			> div {
				@include funcFloat(left);
				@include funcWidth(170);
			}

			> ul {
				@include funcOverflowHidden();
				> li{
					@include funcFloat(left);
					@include funcPaddingLeft(5);

				}
			}
		}
	}

	> div.area_info{
		@include funcFontColor(#777);
		@include funcLineHeight(18);

		> div {
			@include funcPaddingTop(5);
		}
	}
}

.mlSchoolSelect {
	@include funcPaddingBottom(20);

	> div.college_name{
		@include funcPosition(relative);

		> div{
			@include funcPosition(absolute);
			@include funcPositionTop( 40);
			@include funcPositionLeft(33);
			@include funcFont(22);
			@include funcLineHeight(40);

			> span.name{
				@include funcFont(35);
				@include funcFontColor(#ba0000);
			}
		}
	}

	> div.select_all {
		@include funcFont($dgFontSizeBase + 3);

		> div.ttl_select_school {
			@include mgBlockSpace();
			@include funcPadding(6, 0, 5, 50);
			@include mgFontBoldWithSize($dgFontSizeBase + 2);
			@include funcLineHeight(37);
			@include funcBackgroundNoRep(url("/assets/#{$theme}/images/bg_ttl_select_route.webp"), left, top);
		}

		> div.search_num {
			@include funcBorderBottom( 1, solid, #000);
			@include funcPaddingBottom(10);

			> span{
				@include funcFontWeight(bold);
				@include funcFont(16);
			}

			> span.red{
				@include funcFontColor(red);
			}
		}
		> div.no_data {
			@include funcMarginVerHori( 15, 0);
			@include funcTextAlign(center);

			> p.no_data_txt {
				@include mgBlockSpace();
				@include funcPaddingVerHori( $dgBoxMarginBase, 0);
				@include funcBkColor(#fefa95);

				> span.title_txt {
					@include funcMarginBottom( $dgBoxPaddingBase);
					@include mgFontBoldWithSize($dgFontSizeBase + 4);
					@include funcDisplay(block);
				}
			}
		}

		> div {
			@include funcPaddingTop(10);

			> table {
				@include funcBorder($dgBorderWidthBase, solid, $dgBorderColorBase);
				@include funcWidthPcnt(100);

				> tbody {
					>tr{
						> th{
							@include funcLineHeight(30);
							@include funcVerticalAlign(middle);
							@include funcBorder($dgBorderWidthBase, solid, $dgBorderColorBase);
							@include funcBackground(url("/assets/base/images/background/bg_gray_cross.webp"));
						}
						> td{
							@include funcLineHeight(26);
							@include funcPaddingLeft(10);
							@include funcVerticalAlign(middle);
							@include funcFont(13);
							@include funcBorder($dgBorderWidthBase, solid, $dgBorderColorBase);
						}
						> td.college_name{
							@include funcVerticalAlign(baseline);
							@include funcFontWeight(bold);
							@include funcFont(14);
						}
					}
				}
			}
		}

		> div.result_list{

			@include funcFont(14);
			@include funcMarginTop(15);

			> div {
				@include funcBackgroundRepX(url("/assets/base/images/border_dashed.webp"), left, top);

				> table {
					> tbody{
						> tr{
							> td{
								@include funcOverflowHidden();
								@include funcBorderBottom(1,dotted,#ccc);
								@include funcPaddingAll(10);

								@include funcVerticalAlign(middle);

								> ul {
									> li{
										@include funcLineHeight(26);

										> img {
											@include funcPaddingBottom(5);
										}
									}
								}

							}
							> td.department{
								@include funcWidth(230);

								> img{
									@include funcPaddingRight(5);
								}

								> span.small{
									@include funcFont(11);
									@include funcLineHeight(14);
								}
							}

						}
					}
				}

			}
		}
	}
}

@mixin mlOneHeader2Column{
	> .top_img {
		@include funcPosition(relative);
		@include funcMarginBottom( 20);
	}

	> .main_column {
		> .search_select {
			@include funcOverflowHidden();

			> .search_list {
				@include funcFloat(left);
				@include funcFont(16);
				@include funcCursorPointer();

				> div {
					> .title_text {
						@include funcPaddingLeft( 40);
						@include funcLineHeight(45);
					}


				}
			}

			> .active{
				> div {
					@include funcMarginTop(5);
				}
			}

			> .ensen_tab {
				@include funcWidth(188);
				@include funcHeight(46);
				@include funcOverflowHidden();
				@include funcBackgroundNoRep(url("/assets/#{$theme}/images/tab_ensen.webp"), left, top);
			}

			> .area_tab {
				@include funcMarginLeft(10);
				@include funcWidth(158);
				@include funcHeight(46);
				@include funcOverflowHidden();
				@include funcBackgroundNoRep(url("/assets/#{$theme}/images/tab_area.webp"), left, top);
			}

		}

		> .search_navi_content_ensen {
			@include funcWidth(715);
			@include funcHeight(385);
			@include mgContentCircleBox( 10, $theme, top);
			@include funcMarginTop( -6);

			> .js1c_table {
				@include funcWidth(675);
				@include funcHeight(338);
				@include funcMarginTop( 21);
				@include funcMarginLeft(20);
				@include funcOverflowHidden();
				border-bottom: 1px solid;
				@include funcBackgroundNoRep(url("/assets/#{$theme}/images/bg_top_main_base_ensen.webp"), left, top);

				> tbody {
					> tr {
						@include funcHeight(42);

						> th {
							@include funcWidth(110);

							@include funcVerticalAlign(middle);
							@include funcFontWeight(bold);
						}
						> td {

							@include funcVerticalAlign(middle);
							@include funcPaddingTopSideBtm( 0, 13, 0);

							> span.no_link{
								@include funcFontColor(#bdbdbd);
							}
						}
					}
				}
			}
		}
		> .search_navi_content_city {
			@include funcWidth(715);
			@include funcHeight(385);
			@include mgContentCircleBox( 10, $theme, top);
			@include funcMarginTop( -6);

			> .js1c_table {
				@include funcWidth(675);
				@include funcHeight(338);
				@include funcMarginTop( 21);
				@include funcMarginLeft(20);
				@include funcOverflowHidden();
				border-bottom: 1px solid;
				@include funcBackgroundNoRep(url("/assets/#{$theme}/images/bg_top_main_base_area.webp"), left, top);

				> tbody {
					> tr {
						@include funcHeight(42);

						> th {
							@include funcWidth(110);

							@include funcVerticalAlign(middle);
							@include funcFontWeight(bold);
						}
						> td {

							@include funcVerticalAlign(middle);
							@include funcPaddingTopSideBtm( 0, 13, 0);

							> span.no_link{
								@include funcFontColor(#bdbdbd);
							}
						}
					}
				}
			}
		}

	}
}

.mlNewBukkenTitle {
	@include funcWidth($dgBoxWidthMain);
	@include funcOverflowHidden();
	@include mgContentCircleBoxTtlImg( h3, $theme, url("/assets/base/images/background/bg_ttl_newly.webp"));

	@include funcBorderNone();

	> h3 {
		@include funcPadding(0, 0, 5, 68);
		@include funcLineHeight(53);
		z-index: 2;

		@include funcHeight(53);
		@include funcOverflowHidden();
	}
}

.mlNewBukkenList {
	@include funcOverflowHidden();
	@include mgContentCircleBoxTtl( h3, $theme);

	> div {
		@include funcPaddingTopSideBtm( 10, 10, 0);

		> ul {
			@include funcMarginBottom( 10 * 2);
			@include funcWidth(164 * 4 + 12 * 4);
			@include funcOverflowHidden();

			> li {
				@include funcMargin(10, 12, 0, 0);
				@include funcWidth(164);
				@include funcFloat(left);


				> div.recommend_info {
					@include funcMarginTop( 10);
					@include funcFontWeight(bold);


					> span {
						@include funcDisplay(block);
						@include funcFont($dgFontSizeBase + 2);
						@include funcLineHeight($dgFontSizeBase + 4);
					}

					> span.data_name {
						@include funcMinHeight(30);

					}

					> span.data_addition {
						@include funcFontWeight(normal);
						@include funcPaddingBottom(15);
					}
				}
			}
		}
	}
}

.mlSobaTable {

	> div {
		@include funcPaddingTop( 10);
		@include funcLineHeight(15);
	}
	> table{
		@include funcBorderTop( 2, dotted, #f5e6c9);
		@include funcBorderRight( 2, dotted, #f5e6c9);
		@include funcMarginTop(20);
		@include funcMarginBottom(40);

		> tbody {

			> tr {
				> th{
					@include funcHeight(40);
					@include funcWidth(190);
					@include funcFontWeight(bold);
					@include funcVerticalAlign(middle);
					@include funcTextAlign(center);
					@include funcBkColor(#eee);
					@include funcBorderLeft( 2, dotted, #f5e6c9);
					@include funcBorderBottom( 2, dotted, #f5e6c9);
				}

				> td{
					@include funcHeight(40);
					@include funcWidth(190);
					@include funcVerticalAlign(middle);
					@include funcTextAlign(center);
					@include funcBorderLeft( 2, dotted, #f5e6c9);
					@include funcBorderBottom( 2, dotted, #ede8de);

				}

				>td.min{
					@include funcBkColor(#ccefff);
				}

				> td.max{
					@include funcBkColor(#ffe5e9);
				}

				> th.title {
					@include funcHeight(50);
					@include funcBkColor(#f5e6c9);
					@include funcTextAlign(left);
					@include funcPaddingLeft( 10);
					@include funcFont(15);
					@include funcLineHeight(20);
					@include funcBorderBottom( 2, dotted, #ffffff);
				}

				> td.title {
					@include funcBkColor(#ede8de);
					@include funcTextAlign(left);
					@include funcPaddingLeft( 10);
					@include funcFont(15);
					@include funcBorderBottom( 2, dotted, #ffffff);
				}

			}
		}
	}
}

.mlSchoolDeals {

	@include funcMarginTop( 10);
	@include funcWidth($dgBoxWidthMain - 2);
	@include funcOverflowHidden();
	@include mgContentCircleBox($dgBoxTopBorderSize, $theme, top);

	> h3 {
		@include funcPadding(0, 0, 5, 68);
		@include funcLineHeight(53);
		z-index: 2;
	}

	> h3.commuter {
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_ttl_commuter.webp"), left, top);
	}

	> h3.map{
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_ttl_map.webp"), left, top);
	}

	> h3.shop{
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_ttl_shop.webp"), left, top);
	}

	> h3.list{
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_ttl_list.webp"), left, top);
	}

	> div.data_wrapper {
		@include funcMarginTop( -6);
		@include funcOverflowHidden();
		@include funcPadding(0,10,10,10);
		z-index: 1;

		> div.comment{
			@include funcPaddingAll(10);
			@include funcLineHeight(18);
		}

		> div.map_comment{
			@include funcOverflowHidden();
			@include funcPadding(10,5,10,5);
			@include funcLineHeight(18);

			> div{
				@include funcWidth(400);
				@include funcFloat(left);
			}

			> form {
				@include funcFloat(right);
			}
		}
		> div.shop_comment{
			@include funcBackgroundNoRep(url("/assets/base/images/background/bg_school_contact_shop.webp"), left, top);
			@include funcHeight(100);
			@include funcLineHeight(18);
			@include funcPaddingLeft(150);
			@include funcPaddingTop(25);
		}

		> ul {
			> li{
				> table {
					>tbody{
						>tr{
							>td{
								@include funcVerticalAlign(middle);
								@include funcPaddingAll(10);
							}
							>td.railroad{
								@include funcWidth(330);
								@include funcLineHeight(16);

								> span.eki{
									@include funcFont(16);
									@include funcFontWeight(bold);
									@include funcLineHeight(24);
								}
							}
						}

						>tr.commuter{
							@include funcBorderTop( 1, dotted, #ccc);

							> td.commuter1{
								@include funcBackgroundNoRep(url("/assets/base/images/background/bg_school_neweki1.webp"), left, top);
								@include funcPaddingLeft(25);
							}
							> td.commuter2{
								@include funcBackgroundNoRep(url("/assets/base/images/background/bg_school_neweki2.webp"), left, top);
								@include funcPaddingLeft(25);
							}
							> td.commuter3{
								@include funcBackgroundNoRep(url("/assets/base/images/background/bg_school_neweki3.webp"), left, top);
								@include funcPaddingLeft(25);
							}

							> td.search{
								> form {
									> *{
										@include funcVerticalAlign(middle);
									}
								}
							}
						}
					}
				}
			}
		}

		> form {
			> ul{
				> li.school_hand_shop{
					@include funcBorderRadius( 5);
					@include funcBorder(1, solid, #ccc);
					@include funcMarginTop(5);

					> table{

						> tbody {
							> tr {
								> td{
									@include funcVerticalAlign(middle);
									@include funcPaddingAll(10);
								}
								> td.shop_detial{
									@include funcLineHeight(20);
									@include funcWidth(490);

									> a {
										@include funcFont(14);
										@include funcFontWeight(bold);
									}
								}

								> td.shop_img{
									> img{
										@include funcBorder(1, solid, #ccc);
									}
								}
							}
						}
					}
				}
			}
			> div.shop_contact{
				@include funcMarginVerHori(0,130);
				@include funcMarginTop(10);
			}
		}

		> div.map_search {
			@include funcPosition(relative);

			> div.box_attention{
				@include funcPosition(absolute);
				@include funcWidth(693);
				@include funcHeight(412);
				@include funcPaddingTop(160);
				@include funcPaddingLeft(120);
				@include funcCursorPointer();
				z-index:1;
			}

			> div.map_area {
				@include funcPosition(relative);
				@include funcHeight(400);
				@include funcPaddingAll( 5);
				@include funcMarginAll(5);
				@include mgRoundCornerTop($dgBoxRadiusBase, $dgBorderWidthBase, solid, $dgBorderColorBase);
			}
		}
	}
}

.school_side_column{
	@include funcWidth(225);
	@include funcFloat(right);
	@include funcMarginTop(7);
}
.loan_caution{
	@include funcBorder(1,solid,#ff0000);
	@include funcLineHeight(20);
	@include funcPaddingAll(10);
	@include funcFontColor(#ff0000);
	@include funcMarginBottom(10);
	@include funcMarginTop(10);
}
.rating_star {
    position: relative;
    z-index: 0;
    display: inline-block;
    white-space: nowrap;
    color: #CCCCCC;
}
.rating_star:before, .rating_star:after{
    content: '★★★★★';
}
.rating_star:after{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    color: #ffcf32; /* イエローカラー 自由に設定化 */
}
.rating_star[data-rate="5"]:after{ width: 100%; }
.rating_star[data-rate="4"]:after{ width: 80%; }
.rating_star[data-rate="3"]:after{ width: 60%; }
.rating_star[data-rate="2"]:after{ width: 40%; }
.rating_star[data-rate="1"]:after{ width: 20%; }
.rating_star[data-rate="0"]:after{ width: 0%; }
@mixin funcTopSideDesign()
{
	> div.top_side_info{
		@include funcTextAlign(left);

		@include funcMarginBottom(17);
		@include funcPaddingTop(8);
		@include funcBackgroundNoRep(url(/assets/top/images/bg_top_side_box_top.webp),left, top);

		> div.content_box_btm {
			@include funcPaddingBottom(9);
			@include funcBackgroundNoRep(url(/assets/top/images/bg_top_side_box_btm.webp),left,bottom);
			> div.content_box_mid {
				@include funcPadding(0,4,0,3);
				@include funcBackgroundRepY(url(/assets/top/images/bg_top_side_box_mid.webp), left, top);
				> h3{
					@include funcPaddingTopSideBtm(11,15,0);
				}
				> ul{
					@include funcPaddingVerHori(15,15);
					>li{
						> span{
							@include funcFontWeight(bold);
							@include funcLineHeight(20);
						}
						> ul{
							@include funcOverflowHidden();
							@include funcPaddingTop(10);
							> li{
								@include funcFloat(left);
								@include funcPaddingRight(4);
							}
						}
					}
				}
			}
		}
	}
}
