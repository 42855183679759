
.mlRailroadSelect {
	@include mgContentCircleBoxTtl( h3, $theme);
	@include mgSelectList((($dgBoxWidthBase - $dgBorderWidthBase * 2 - $dgBoxPaddingBase * 5 - 3) / 4));
	
	> h3 {
		@include funcPadding($dgBoxPaddingBase, $dgBoxPaddingBase, $dgBoxPaddingBase, 37);
		@include funcBackgroundNoRep(url("/assets/#{$theme}/images/icn_circle.webp"), 1px * $dgBoxPaddingBase + 2, 50%);
	}
	
	> ul {
		@include funcBackgroundRepX(url("/assets/base/images/border_dashed.webp"), left, top);
		
		> li {
			@include funcFont($dgFontSizeBase + 3);
		}
	}
}


.mlRailroad {
	> div.ttl_select_railroad {
		@include mgBlockSpace();
		@include funcPadding(6, 0, 5, 50);
		@include mgFontBoldWithSize($dgFontSizeBase + 2);
		@include funcLineHeight(37); 
		@include funcBackgroundNoRep(url("/assets/#{$theme}/images/bg_ttl_select_route.webp"), left, top);
	}
}

.mlStationSelect {
	@include funcFont($dgFontSizeBase + 3);
	@include mgContentCircleBoxTtlIcon( h3, $theme);
	
	> div.select_area {
		@include funcPadding($dgBoxPaddingBase, $dgBoxPaddingBase, 0, $dgBoxPaddingBase);
		@include funcBackgroundRepX(url("/assets/base/images/border_dashed.webp"), left, top);
		
		> div.select_all {
			@include funcMarginBottom( $dgBoxPaddingBase);
			@include funcFont($dgFontSizeBase + 3);
			
			> input[type="checkbox"] {
				@include funcVerticalAlign(middle);
			}
		}
		
		> ul {
			@include funcOverflowHidden();
			
			> li.each_station {
				@include mgBlockSpace();
				@include funcPaddingTop( 20);
				@include funcWidth(66);
				@include funcFloat(left);
				@include funcTextAlign(center);
				word-break: break-all;
				
				
				@include funcBackgroundRepX(url("/assets/component/images/tmpEki/bg_railroad.webp"), left, top);
				
				> div.station_name {
					@include funcMarginAuto();
					@include funcPaddingVerHori( 5, 0);
					@include funcWidth($dgFontSizeBase + 3);
					writing-mode: vertical-rl;
				}
				
				> span.add_data {
					@include funcFont($dgFontSizeBase);
					@include funcFontColor($dgFontColorHalf);
				}
			}
			
			> li.canceler {
				clear: left;
			}
		}
	}
}

.mlStationSearch {
	
	> div.title{
		@include mgBlockSpace();
	}
}