
@mixin mlContentUnderTitle()
{
	@include mgBlockSpace();
	@include funcPaddingTopSideBtm( 9, 10, 10);
	@include funcBorderRadiusBottom( $dgBoxRadiusBase);
	@include funcBorderTop( 1, solid, #cecece);
	@include funcBackground($dgBgImageCross);

	> div {
		@include funcPaddingTop( 8);
		@include funcBackgroundNoRep(url("/assets/base/images/background/bg_result_condition_box_top.webp"), left, top);

		> div {
			@include funcPaddingBottom( 6);
			@include funcBackgroundNoRep(url("/assets/base/images/background/bg_result_condition_box_btm.webp"), left, bottom);

			> div {
				@include funcPaddingTopSideBtm( 10 - 8, 10, 0);
				@include funcOverflowHidden();
				@include funcBackgroundRepY(url("/assets/base/images/background/bg_result_condition_box_mid.webp"), left, top);
			}
		}
	}
}

.mlResultCondition {
	@include funcMarginTop( -2);
	@include mlContentUnderTitle();

	> div.result_condition_box_top {

		> div.result_condition_box_btm {

			> div.result_condition_box_mid {

				> div.result_ttl {
					@include funcMarginBottom( 10);
					@include mgVerticalImage();
					@include funcOverflowHidden();

					> ul{
						> li {
							@include funcFloat(left);
							> span.txt_highlight {
								@include funcPaddingLeft( 5);

								@include funcFontColor($dgFontColorPitat);
							}

							> span.save_condition {
								@include funcLineHeight(20);
								@include funcFontWeight(bold);
								@include funcFloat(right);
								@include mgVerticalImage();
							}
						}

						> li.cond{
							@include funcWidth(430);
							@include funcLineHeight(19);
							@include funcMarginAll(5);
						}
					}
				}

				> div.newly_condsave{
					@include mgVerticalImage();
					@include funcMarginAll(10);
					@include funcOverflowHidden();

					>ul{
						@include funcOverflowHidden();
						@include funcFloat(right);

						> li{
							@include funcFloat(left);

							@include funcPaddingLeft(12);
							@include funcTextAlign(center);

							> span.save_condition {
								@include funcLineHeight(20);
								@include funcFontWeight(bold);
								@include mgVerticalImage();
							}

							> span.save_newly{
								@include funcLineHeight(20);
								@include funcFontWeight(bold);
								@include mgVerticalImage();
							}
						}
					}
				}


				> div.station_name {
					@include funcMarginBottom( 10);
					@include funcOverflowHidden();
					@include funcBorderRadius($dgBoxRadiusBase);
					@include funcBkColor(#eaeaea);

					> div.line{

						> ul {
							@include funcOverflowHidden();

							> li{
								@include funcFloat(left);


								> img.prev, img.next {
									@include funcMargin(7, 0, 7, 10);

									@include funcLineHeight(37);
									@include funcCursorPointer();
								}

								> div.viewport{
									@include funcMarginLeft(5);

									@include funcWidth(600);
									@include funcOverflowHidden();
									@include mgVerticalImage();

									> ul {
										@include funcFloat(left);
										@include funcOverflowHidden();

										> li.item {
											@include funcFloat(left);
											@include funcWidth(97);
											@include funcLineHeight(35);
											@include funcCursorPointer();

											> input[type="checkbox"] {
												@include funcVerticalAlign(middle);
											}

											> div {
												@include funcMarginTopSideBtm( 12, 0, 1);
												@include funcWidth(75);
												@include funcFloat(right);
												@include funcLineHeight(13);
											}
										}
									}
								}
							}
						}
					}
				}

				> div.condition_refinement {
					@include funcMarginBottom( 10 - 6);
					@include funcLineHeight(26);
					@include funcOverflowHidden();
					@include mgRoundCorner($dgBoxRadiusBase, $dgBorderWidthBase, solid, #b5b5b5);

					> table{
						> tbody{
							> tr {
								> th {
									@include funcBkColor(#b5b5b5);
									@include funcWidth(88);
									> span.refine_ttl {
										@include funcPaddingAll( 8);
										@include funcFontColor($dgFontColorInvert);
										@include funcFontWeight(bold);
										@include funcBkColor(#b5b5b5);
									}
								}
								> td{
									@include funcPaddingLeft( 5);
									> span.refine_list {
										@include funcPaddingVerHori( 0, 10);
										@include funcOverflowHidden();
									}
								}
							}
						}
					}
				}

				> div.search_commuting {
					@include funcMarginBottom( 10);
					@include funcPaddingLeft( 5);
					@include funcOverflowHidden();
					@include funcLineHeight(38);
					@include funcBorderRadius($dgBoxRadiusBase);
					@include funcBkColor(#eaeaea);

					> input[type="text"] {
						@include funcWidth(135);
					}
				}

				> div {

					> span.search_btn {
						@include funcMarginTop( 1);
						@include funcFloat(right);
					}
				}
			}
		}
	}
}


.mlPaginate {
	@include funcPaddingTopSideBtm( 10, 0, 5);
	@include funcOverflowHidden();
	@include funcBkColor(#fff6d1);
	> div.button_area {
		@include funcLineHeight(34);
		@include funcFloat(left);

		> ul {

			> li {
				@include funcMarginRight( 10);
				@include funcFloat(left);
				@include funcLineHeight(27);

				> a {
					@include mgVerticalImage();
				}
			}

			> li.bottom{
				@include funcMarginTop( 10);
			}

			> li.bottom_img{
				@include funcMarginTop( 0);
			}
		}
	}

	> div.paginate_area {
		@include funcMargin(6, -4, 6, 0);
		@include funcMaxWidth(214 + 4);
		@include funcFloat(right);

		> ul {
			@include funcOverflowHidden();

			> li{
				@include funcHeight(23);
				@include funcMarginRight( 4);
				@include funcFloat(left);
				@include funcTextAlign(center);

				> a {
					@include funcPaddingTopSideBtm( 5, 6, 4);
					@include funcFontColor($dgFontColorBase);
					@include funcFontWeight(bold);
					@include funcVerticalAlign(middle);
					@include funcBorderRadius($dgBoxRadiusSmall);
					@include mgContentBorder();
					@include funcBkColor($dgBoxColorBase);
					@include funcDisplay(block);
				}

				> a:hover {
					@include funcTextDecoration(none);
					@include funcBackgroundRepX(url("/assets/base/images/background/bg_paginate.webp"), left, top);
				}

				> span.active {
					@include funcPaddingTopSideBtm( 5 + $dgBorderWidthBase, 6 + $dgBorderWidthBase, 4 + $dgBorderWidthBase);
					@include funcFontColor($dgFontColorInvert);
					@include funcFontWeight(bold);
					@include funcVerticalAlign(middle);
					@include funcBorderRadius($dgBoxRadiusSmall);
					@include funcBkColor(#999);
					@include funcDisplay(block);
				}
			}

			> li.no_design {
				@include funcPaddingAll( 0);
				@include funcBorderNone();

				> a {
					@include funcPaddingAll( 0);
					@include funcBorderNone();
				}
			}
		}
	}
}


.mlSorting {
	@include funcMarginBottom( 45);
	@include funcFontWeight(bold);

	> div.result_count {
		@include funcFloat(left);

		> span.total_count {
			@include funcFont($dgFontSizeBase + 10);
			@include funcFontColor($dgFontColorAccent);
			@include funcVerticalAlign(middle);
		}
	}

	> div.result_sorting {
		@include funcMarginRight( -$dgBoxPaddingBase);
		@include funcFloat(right);

		> select {
			@include funcMarginRight( $dgBoxPaddingBase);
		}
	}

	.disp_count {
		margin-left: 10px;
		vertical-align: -1px;
	}

	.attention {
		position: relative;
		display: inline-block;
		z-index: auto;
		vertical-align: -3px;

		> img {
			margin-left: 10px;
		}
	}

	.attention_box {
		display: none;
		position: absolute;
		width: 325px;
		height: 35px;
		top: -80px;
		left: -35px;
		padding: 8px;
		border-radius: 5px;
		border: solid 5px #B5B5B5;
		background: #fff;
		font-weight: normal;
		line-height: 18px;
	  }

	.attention_box:after {
		position: absolute;
		content: "";
		width: 0px;
		height: 0px;
		top: 55px;
		left: 33px;
		border: 16px solid transparent;
		border-top: 16px solid #B5B5B5;
	}

	img:hover + .attention_box {
		display: block;
		z-index: 2;
	}

}

.mlSortingSale {
	@include funcMarginBottom( 25);
}

@mixin mlResultDataInfo($size)
{
	@include mgMapRouteIcon(20);
	@include funcOverflowHidden();

	>li {
		@include funcMaxWidth($size);
		@include funcFloat(left);
	}

	> li.data_address {
		@include funcMarginRight( 10);
		@include funcMarginBottom( 5);
	}
}

@mixin mlResultBuildingData()
{

	> div.photo_data {
		@include funcWidth(78 * 2 + $dgBorderWidthBase * 3);
		@include funcFloat(left);
		@include funcPosition(relative);

		> img.icon_new {
			@include funcPosition(absolute);
			@include funcPositionTop( -2);
			@include funcPositionLeft( -2);
		}

		> ul {
			@include funcOverflowHidden();
			@include funcBorderTop( $dgBorderWidthBase, solid, $dgBorderColorBase);
			@include funcBorderLeft( $dgBorderWidthBase, solid, $dgBorderColorBase);

			> li {
				@include funcFloat(left);
				@include funcBorderRight( $dgBorderWidthBase, solid, $dgBorderColorBase);
				@include funcBorderBottom( $dgBorderWidthBase, solid, $dgBorderColorBase);
			}
		}
	}

	> div.data_detail {
		@include funcMarginLeft(78 * 2 + $dgBorderWidthBase * 3 + 10);

		> ul.icon_box {
			@include funcMarginBottom( 10);
			@include funcOverflowHidden();

			> li {
				@include funcMarginRight( 2);
				@include funcFloat(left);
			}
		}

		> div.data_catch {
			@include funcMarginBottom( 10);
			@include mgFontBoldWithSize($dgFontSizeBase + 4);
		}

		> div.data_price {
			@include mgPriceValue($dgFontSizeBase * 2);

			> img {
				@include funcMarginBottom( 2);
			}
		}

		> ul.data_info {
			@include mlResultDataInfo(213);
		}

		> ul.add_info {
			@include funcOverflowHidden();
			@include funcDisplay(flex);
			@include funcBorderRight( $dgBorderWidthBase, solid, $dgBorderColorBase);
			@include funcBorderBottom( $dgBorderWidthBase, solid, $dgBorderColorBase);

			> li {
				@include funcPaddingAll( 8);
				@include funcWidth(113);
				@include funcFloat(left);
				@include funcLineHeight(16);
				@include funcBorderLeft( $dgBorderWidthBase, solid, $dgBorderColorBase);
			}
		}
	}
}

@mixin mlResultParkingData()
{

	> div.data_detail {
		@include funcOverflowHidden();

		> div.data_price {
			@include funcOverflowHidden();

			> div.parking_price {
				@include funcFloat(left);
				@include mgPriceValue($dgFontSizeBase * 2);
			}

			> div.data_vacant {
				@include funcWidth(195);
				@include funcFloat(right);

				> span.data_value {
					@include funcFont($dgFontSizeBase * 2);
				}
			}
		}

		> ul.data_info {
			@include mlResultDataInfo(328);
		}
	}
}

@mixin mlResultNoData()
{
	@include funcMarginVerHori( 15, 0);
	@include funcTextAlign(center);

	> p.no_data_txt {
		@include mgBlockSpace();
		@include funcPaddingVerHori( $dgBoxMarginBase, 0);
		@include funcBkColor(#fefa95);

		> span.title_txt {
			@include funcMarginBottom( $dgBoxPaddingBase);
			@include mgFontBoldWithSize($dgFontSizeBase + 4);
			@include funcDisplay(block);
		}
	}
}

.mlGroupResultListed{

	> div.no_data{
		@include mlResultNoData();
	}

	> div.result_list {
		@include mgContentCircleBox( $dgBoxTopBorderSize - 2, $theme, -2px);

		> div.result_data {

			> div.result_ttl {
				@include funcPaddingVerHori( 13, 8);
				@include funcBackgroundRepX(url("/assets/base/images/background/bg_ttl_result_list.webp"), left, bottom);

				> ul {
					@include funcOverflowHidden();

					> li {
						@include funcFloat(left);
						@include funcMarginLeft(5);
						@include funcLineHeight(24);
						@include funcPaddingTop(1);
						@include funcPaddingBottom(1);

						> h3 {
							@include mgFontBoldWithSize($dgFontSizeBase + 4);
							@include funcDisplay(inline);
							@include funcVerticalAlign(middle);

								@include funcVerticalAlign(middle);

						}

						> span{
							@include funcVerticalAlign(middle);
						}
					}

					> li.toukubun{

						> div {
							@include funcPaddingLeft(4);
							@include funcPaddingRight(4);
							@include funcBorder(1,solid,$theme_color);
							@include funcFontColor($theme_color);
							@include funcMarginLeft(15);
						}


					}

					> li.range{
						@include funcFloat(right);
						@include funcFontWeight(bold);
						@include funcFontColor(#dd3e3e);
						@include funcMarginRight(20);
						@include funcFont(14);
					}
				}
			}

			> div.result_detail{
				@include funcOverflowHidden();
				@include funcPaddingAll( 10);

				> div {
					@include funcFloat(left);
				}

				> div {
					> img{
						@include funcBorder(1,solid,#ccc);
					}
				}
				> div.data_info{
					@include funcPaddingLeft(10);
					@include funcWidth(525);
					@include funcOverflowHidden();
					@include funcMinHeight(157);

					> dl{
						@include funcOverflowHidden();
						@include funcDisplay(table);

						> dt {
							@include funcDisplay(table-cell);
							@include funcLineHeight(18);
							@include funcPaddingRight(5);
							@include funcPaddingBottom(5);
						}

						> dd {
							@include funcFont(13);
							@include funcDisplay(table-cell);
							@include funcWidth(250);
							@include funcLineHeight(18);
							@include funcPaddingBottom(5);
						}
					}

					> div.staff_comment{
						@include funcLineHeight(16);

						@include funcMarginTop(13);

						dl.comment{

							@include funcOverflowHidden();

							> dt {
								@include funcPaddingAll( 5);
								@include funcBkColor(#E8E8E8);
								@include funcDisplay(table-cell);
								@include funcVerticalAlign(middle);
								@include funcTextAlign(center);
							}
							> dd{
								@include funcPaddingAll( 5);
								@include funcDisplay(table-cell);
								@include funcBkColor(#F7F5D2);
								@include funcWidth(408);
								@include funcVerticalAlign(middle);
							}
						}
					}
				}
			}

			> div.kukaku_list{
				@include funcPaddingLeft(10);
				@include funcPaddingRight(10);
				@include funcPaddingBottom(20);

				> dl{

					> dt{
						@include funcBorderTop(1,solid, #ccc);
						@include funcBorderLeft(1,solid, #ccc);
						@include funcBorderRight(1,solid, #ccc);



						@include funcBackgroundRepX(url("/assets/base/images/background/bg_ttl_result_list.webp"), left, bottom);

						@include funcOverflowHidden();
						> span{
							@include funcDisplay(block);
							@include funcPaddingTop(12);
							@include funcPaddingBottom(12);
							@include funcFloat(left);
							@include funcTextAlign(center);
							@include funcFontWeight(bold);
						}

						> span.short{
							@include funcWidth(40);
						}

						> span.middle{
							@include funcWidth(80);
						}

						> span.large{
							@include funcWidth(130);
						}

						> span.blarge{
							@include funcWidth(164);
						}
						> span.mlarge{
							@include funcWidth(125);
						}
						> span.newshort{
							@include funcWidth(37);
						}
						> span.newmiddle{
							@include funcWidth(80);
						}
						> span.newlarge{
							@include funcWidth(130);
						}
						> span.newblarge{
							@include funcWidth(160);
						}
						> span.newmlarge{
							@include funcWidth(100);
						}
					}
					> dd{
						> ul {
							@include funcBorderLeft(1,solid, #ccc);
							@include funcBorderRight(1,solid, #ccc);
								@include funcBorderBottom(1,solid, #ccc);

							>li.auction{
								@include funcBkColor(#fffaf2);
							}

							>li{
								@include funcDisplay(table);


								> div{
									@include funcDisplay(table-cell);
									@include funcLineHeight(16);
									@include funcPaddingTop(5);
									@include funcPaddingBottom(5);
									@include funcVerticalAlign(middle);
									@include funcTextAlign(center);

									> span.data_value{
										@include funcFontWeight(bold);
										@include funcFontColor(#dd3e3e);
									}

									> img{
										@include funcVerticalAlign(middle);
									}

								}

								> div.chk{
									@include funcBkColor(#fff6d1);
								}
								> div.short{
									@include funcWidth(40);
								}

								> div.middle{
									@include funcWidth(80);
								}

								> div.large{
									@include funcWidth(130);
								}

								> div.blarge{
									@include funcWidth(165);
								}
								> div.mlarge{
									@include funcWidth(125);
								}
								> div.large{
									@include funcWidth(145);
								}
								> div.newshort{
									@include funcWidth(37);
								}
								> div.newmiddle{
									@include funcWidth(80);
								}
								> div.newlarge{
									@include funcWidth(130);
								}
								> div.newblarge{
									@include funcWidth(160);
								}
								> div.newmlarge{
									@include funcWidth(100);
								}

								> div.overall{
									@include funcWidth(690);
									@include funcPaddingLeft(20);
									@include funcTextAlign(left);
									@include funcLineHeight(26);
									@include funcFont(13);

									> span{
										@include funcFontWeight(bold);
										@include funcFontColor(#dd3e3e);

									}
								}

								> div.auction {
									.auction_box {
										display: none;
										position: absolute;
										width: 315px;
										height: 15px;
										margin-top: -80px;
    									margin-left: -17px;
										padding: 8px;
										border-radius: 5px;
										border: solid 5px #B5B5B5;
										background: #fff;
										font-weight: normal;
										line-height: 18px;
									  }

									.auction_box:after {
										position: absolute;
										content: "";
										width: 0px;
										height: 0px;
										top: 35px;
										left: 33px;
										border: 16px solid transparent;
										border-top: 16px solid #B5B5B5;
									}

									img:hover + .auction_box {
										display: block;
										z-index: 2;
									}
									.auction_box2 {
										display: none;
										position: absolute;
										width: 315px;
										height: 15px;
										margin-top: -80px;
    									margin-left: -34px;
										padding: 8px;
										border-radius: 5px;
										border: solid 5px #B5B5B5;
										background: #fff;
										font-weight: normal;
										line-height: 18px;
									  }

									.auction_box2:after {
										position: absolute;
										content: "";
										width: 0px;
										height: 0px;
										top: 35px;
										left: 33px;
										border: 16px solid transparent;
										border-top: 16px solid #B5B5B5;
									}

									img:hover + .auction_box2 {
										display: block;
										z-index: 2;
									}

									.negotiation_box {
										display: none;
										position: absolute;
										width: 387px;
										height: 15px;
										margin-top: -80px;
    									margin-left: -3px;
										padding: 8px;
										border-radius: 5px;
										border: solid 5px #B5B5B5;
										background: #fff;
										font-weight: normal;
										line-height: 18px;
									  }

									.negotiation_box:after {
										position: absolute;
										content: "";
										width: 0px;
										height: 0px;
										top: 35px;
										left: 33px;
										border: 16px solid transparent;
										border-top: 16px solid #B5B5B5;
									}

									img:hover + .negotiation_box {
										display: block;
										z-index: 2;

									}
								}
							}
						}
					}

				}
			}

		}
	}
}


.mlResultListed {

	> div.result_list {
		@include mgContentCircleBox( $dgBoxTopBorderSize - 2, $theme, -2px);

		> div.result_data {

			> div.result_ttl {
				@include funcPaddingVerHori( 13, 8);
				@include funcBackgroundRepX(url("/assets/base/images/background/bg_ttl_result_list.webp"), left, bottom);

				> ul {
					@include funcOverflowHidden();

					> li {
						@include funcFloat(left);
						@include funcMarginLeft(5);
						@include funcLineHeight(25);
						@include funcPaddingTop(1);
						@include funcPaddingBottom(1);

						> h3 {
							@include mgFontBoldWithSize($dgFontSizeBase + 4);
							@include funcDisplay(inline);
							@include funcVerticalAlign(middle);
							> a {
								@include funcVerticalAlign(middle);
							}
						}

						> input[type="checkbox"] {
							vertical-align: middle;
						}

						> span{
							@include funcVerticalAlign(middle);
						}
					}

					> li.toukubun{

						>div{
							@include funcPaddingLeft(4);
							@include funcPaddingRight(4);
							@include funcBorder(1,solid,$theme_color);
							@include funcFontColor($theme_color);
							@include funcMarginLeft(15);
						}

					}

					> li.range{
						@include funcFloat(right);
						@include funcFontWeight(bold);
						@include funcFontColor(#dd3e3e);
						@include funcMarginRight(20);
						@include funcFont(14);
					}
				}
			}

			> div.staff_comment {
				@include funcBkColor(#FFF8DB);
				@include funcLineHeight(12);
				@include funcOverflowHidden();

				div {
					@include funcFloat(left);
				}

				div.comment{
					@include funcPaddingAll( 10);
				}
			}

			> div.result_detail {
				@include funcPaddingAll( 10);
				@include funcOverflowHidden();

				> div.data_detail {

					> div.data_price {
						@include funcMarginBottom( 10);
					}

					> ul.data_info {
						@include funcPaddingAll( 8);
						@include mgContentBorder();
					}
				}
			}

			> div.building_data {
				@include mlResultBuildingData();
			}

			> div.parking_data {
				@include mlResultParkingData();
			}

			> div.auction{
				@include funcBkColor(#fffaf2);
			}
		}

		> div.shop_in_charge {
			@include funcPaddingAll( 10);
			@include funcOverflowHidden();
			@include funcBackgroundNoRep(url("/assets/base/images/background/bg_btm_result_listed.webp"), left, top);

			> ul {
				@include funcFloat(left);
				@include funcWidth(570);
				@include funcOverflowHidden();
				@include mgRoundCorner($dgBoxRadiusBase - 2, $dgBorderWidthBase, solid, $dgBorderColorBase);
				@include funcBkColor($dgBoxColorBase);

				> li.shop_in_charge_ttl {
					@include funcPaddingAll( 8);
					@include funcFloat(left);
					@include funcFontWeight(bold);
					@include funcBorderRight( $dgBorderWidthBase, solid, $dgBorderColorBase);
				}

				> li.shop_name {
					@include funcPaddingAll( 8);
					@include funcFloat(left);
				}

				> li.shop_tel {
					@include funcPaddingVerHori( 4, 12);
					@include funcFont($dgFontSizeBase + 2);
					@include funcLineHeight(17);
					@include funcFloat(right);
				}
			}

			> a.btn_see_detail {
				@include funcFloat(right);
			}
		}
	}

	> div.no_data {
		@include mlResultNoData();
	}
}

.mlRequireContent {
	@include funcFont($dgFontSizeBase + 2);
	@include mgContentCircleBoxTtlIcon( h3, $theme);

	> div.condition_box{

		> div.requirement {
			@include funcPadding($dgBoxPaddingBase, $dgBoxPaddingBase, 0, $dgBoxPaddingBase);
			@include funcBackgroundRepX(url("/assets/base/images/border_dashed.webp"), left, top);

			> table {
				@include mgBlockSpace();
				@include mgMailFormTable(145);

				> tbody {

					> tr {

						> td {

							> ul {

								> li {
									@include funcMarginRight( $dgBoxPaddingBase);
									@include mgInputFontSize($dgFontSizeBase + 2);

									> input[type="text"] {
										@include funcWidth(300);
									}

									> label {
										@include funcVerticalAlign(middle);

										> input[type="checkbox"] {
											@include funcVerticalAlign(middle);
										}
									}
								}
							}

							> ul.list_classification {
								@include mgListFixWidth( 91);
							}

							> table{
								> tbody{
									> tr{
										> th{
											@include funcPaddingRight(10);
										}
										> td{
											@include funcPaddingRight(10);

											> input[type="text"] {
												@include funcTextAlign(right);
											}
										}
									}
								}
							}
						}
					}
				}
			}

			> div.kodawari {
				@include funcMarginBottom($dgBoxPaddingBase);
			}
		}
	}
}


.mlSearchResultList {
	@include mlColumnMainRight();

	> div.heads_up {
		@include funcPaddingBottom(15);

		div {
			@include funcPaddingAll(10);
			@include funcBorder(1, solid, #f6aeae);
			@include funcFontColor(#f00);
			@include funcLineHeight(15);
		}
	}

	> div.soba {
		@include funcPosition(relative);
		@include funcWidth(960);
		@include funcHeight(130);
		@include funcPaddingBottom( 15);
		@include funcBackgroundNoRep(url("/assets/base/images/background/bg_list_soba.webp"), left, top);

		> div.label {
			@include funcPosition(absolute);
			@include funcPositionLeft(135);
			@include funcPositionTop(45);
			@include funcWidth(300);

			> span{
				@include funcFont(40);
				@include funcLineHeight(40);
			}

			> span.small_txt{
				@include funcFont(18);
			}
		}

		> div.list{
			@include funcPosition(absolute);
			@include funcPositionLeft(440);
			@include funcPositionTop(15);
			@include funcMarginBottom(13);

			> table{
				@include funcFont(13);
				@include funcBorder(1,solid,#aea591);
				@include funcBkColor(#fff);

				@include funcPaddingTop(5);

				> tbody {
					> tr{
						> th{
							@include funcBorder(1,solid,#aea591);
							@include funcVerticalAlign(middle);
							@include funcTextAlign(center);
							@include funcHeight(35);
							@include funcWidth(125);
							@include funcFontWeight(bold);
						}

						> th.active {
							@include funcBkColor(#fdefba);
						}

						> td {
							@include funcBorder(1,solid,#aea591);
							@include funcVerticalAlign(middle);
							@include funcTextAlign(center);
							@include funcHeight(35);
							@include funcWidth(125);
						}

						> td.active{
							@include funcBkColor(#fffae6);
						}
					}
				}
			}

			> span{
				@include funcFont(10);
				@include funcLineHeight(10);
				@include funcFontColor(000);
			}
		}
	}

	> div.side_column {

		> form {

			> div.mlRequireContent {
				@include funcMarginBottom( 0);
				@include funcBorderRadius(0);
				@include funcBorderBottomNone();
				@include funcBorderNone();

				> div.condition_ttl {
					@include funcBorderLeft( $dgBorderWidthBase, solid, $dgBorderColorBase);
					@include funcBorderRight( $dgBorderWidthBase, solid, $dgBorderColorBase);

					> div.condition_ttl {
						@include funcPaddingVerHori( 5, 10);
						@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_ttl_condition.webp"), left, top);
					}
				}

				> div.condition_box {
					@include funcBorderTop( $dgBorderWidthBase, solid, $dgFontColorHalf);
					@include funcBorderBottom( $dgBorderWidthBase, solid, $dgFontColorHalf);
					@include funcBorderLeft( $dgBorderWidthBase, solid, $dgBorderColorBase);
					@include funcBorderRight( $dgBorderWidthBase, solid, $dgBorderColorBase);

					> div.requirement {
						@include funcMarginVerHori( 0, 5);
						@include funcPadding($dgBoxPaddingBase, 0, 0, 5);
						@include funcBackground($dgBgImageCross);

						> div {
							> dl.last {
								padding-bottom: 10px;
    							border-bottom: 2px solid #ccc;
							}
							> dl {
								@include mgBlockSpace();

								> dt {
									@include funcMarginTopSideBtm( 0, 3, 5);
									@include funcPaddingLeft( 5);
									@include funcFontWeight(bold);
									@include mgPitatLineHeight();
									@include funcBorderLeft( 3, solid, $theme_color);
								}

								> dd {
									@include funcFont($dgFontSizeBase + 1);
									@include funcLineHeight(28);

									> span.small_txt {
										@include funcFont($dgFontSizeBase - 1);
									}

									> input[type="text"]{
										@include funcWidth(190);
									}

									> input[type="text"].short{
										@include funcWidth(120);
										@include funcTextAlign(right);
									}

									> div.view_more{
										text-align: center;
										border: 1px solid #ccc;
										@include funcLineHeight(11);

										> a{
											display: block;
											width: 100%;
											padding: 10px 0;
											background-color: #eee;
											-webkit-tap-highlight-color: #f4f4f4;
											tap-highlight-color: #f4f4f4;

											color: #333;
											text-decoration: none;
										}
									}
								}
							}

							> dl.half_content {
								@include funcOverflowHidden();

								> dd {
									@include funcWidth(104);
									@include funcFloat(left);
								}
							}

							> dl.last_content {
								@include funcMarginBottom(0);
								@include funcPaddingBottom( 20);
							}
						}
					}
				}
			}

			> div.select_button {
				@include mgBlockSpace();
				@include funcPaddingTopSideBtm( 12, 9, $dgBoxPaddingBase);
				@include funcTextAlign(left);
				@include funcBorderRadiusBottom( $dgBoxRadiusBase);
				@include mgContentBorder();
				@include funcBorderTopNone();
				@include funcBackgroundNoRep(url("/assets/base/images/background/bg_search_condition.webp"), center, top);

				> a {
					@include mgFontBoldWithSize($dgFontSizeBase + 2);
					@include mgPitatLineHeight();
				}
			}
		}

		> div.mlSearchOtherMethod {
			@include mgBlockSpace();
			@include funcPaddingTopSideBtm( 5, 5, $dgBoxPaddingBase);
			@include funcBorderRadius($dgBoxRadiusBase);
			@include mgContentBorder();

			> dl {
				@include funcBackground(none);
				@include mgListIcon(dd, 16 + 2, 16 + 6, url("/assets/#{$theme}/images/icn_arrow.webp"), left, top);

				> dt {
					@include funcWidthPcnt(100);
					@include funcBorderBottom( 1, solid, $dgFontColorHalf);
					@include funcBkColor(#eaeaea);
				}

				> dd {
					@include funcFont($dgFontSizeBase + 3);
					@include funcMarginLeft($dgBoxPaddingBase);
				}
			}
		}
	}

	> div.side_column {
		> form {
			> div.select_button{
				> input {
					@include funcMarginBottom(10);
				}
			}
		}
	}
}

.mlSearchBtn {
	@include funcTextAlign(center);
	@include funcMarginBottom( $dgBoxPaddingBase);
}
.ui-widget-overlay {
	@include funcWidthPcnt(100);
	@include funcHeightPcnt(100);
	@include funcPosition(absolute);
	@include funcPositionTop( 0);
	@include funcPositionLeft( 0);
	@include funcBackground(url("/assets/base/images/background/bg_overlay.webp"));
}
.close_wrapper {
	@include funcPosition(relative);

	> a.close_btn {
		@include funcPosition(absolute);
		@include funcPositionTop( 0);
		@include funcPositionRight( 5);
	}
}
.all_condition {
	@include funcMarginAuto();
	@include funcPaddingTopSideBtm( $dgBoxMarginBase, $dgBoxMarginBase, 0);
	@include funcOverflowHidden();
	@include funcBorderRadius($dgBoxRadiusBase);
	@include funcBkColor($dgBoxColorBase);

	> div.title {
		@include funcMarginBottom( $dgBoxPaddingBase);
		@include funcTextAlign(center);
	}

	> table {
		@include funcMarginAuto();
		@include funcMarginBottom( $dgBoxPaddingBase);
		@include mlDetailTable($dgFontLineheightBase, 110, 660);

		> tbody {

			> tr {

				> th {
					@include funcVerticalAlign(top);
				}

				> td {

					> ul {

						> li {
							@include funcWidth(162);
							@include funcFloat(left);

						}
					}
				}
			}
		}
	}
}

.mlRecentlyData {
	@include funcMarginAuto();
	@include funcWidth($dgBoxWidthMain);
}

.mlFavoriteData {
	@include funcMarginAuto();
	@include funcWidth($dgBoxWidthMain);

	> div.no_data {
		@include mlResultNoData();
	}

	> input[type="image"] {
		@include funcMarginBottom( 15);
	}

	> div {

		> div.result_list {

			> div.result_data {

				> div.result_ttl {
					@include funcPosition(relative);

					> input[type="image"] {
						@include funcPosition(absolute);
						@include funcPositionRight( 10);
						@include funcPositionTop( 7);
					}
				}
			}
		}
	}
}

.mlCityscape {
	> div.mlSearchResultList{
		> div.itemlink_box {
			@include funcWidth(715);
			@include funcFloat(right);
		}
	}
}

.mlInformation{
	@include funcMarginLeft(10);
	@include funcPaddingBottom(10);
	@include funcFont(11);
	@include funcLineHeight(13);
}

.mlFixToolbar{
	@include funcPosition(fixed);
	@include funcPositionBottom(0);
	@include funcWidthPcnt(100);
	@include funcHeight(77);
	@include funcZIndex(20);
	@include funcBackground(url("/assets/base/images/background/bg_overlay.webp"));

	> div{
		@include funcMarginAuto();
		@include funcWidth(960);
		@include funcPaddingTop(10);

		> ul{
			@include funcDisplay(table);

			> li{
				@include funcDisplay(table-cell);
				@include funcPaddingRight(10);
				@include funcVerticalAlign(middle);
				@include funcFontColor(#fff);
			}
		}
	}
}

.tmpListColumn{
	>div.auction_info{
		@include funcPaddingBottom(20);

		> img{
			@include funcVerticalAlign(middle)
		}
	}
	>div.auction_info_bottom{
		@include funcPaddingTop(20);
		@include funcPaddingBottom(20);

		> img{
			@include funcVerticalAlign(middle)
		}
	}
}
#loading {
	display: table;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #ccc;
	opacity: 0.4;
}

#loading .loadingMsg {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	padding-top: 140px;
	background: url("/assets/base/images/fancybox_loading@2x.gif") center center no-repeat;
}
.hyojikubunList {
	padding: 3px;
	border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    > div {
    	padding: 5px 0px 0px 5px;
    	background: url(/assets/base/images/background/bg_gray_cross.webp);
    	> dl {
	    	> dt {
		    	margin: 0px 3px 0px;
			    padding-left: 5px;
			    font-weight: bold;
			    line-height: 20px;
			    border-left: 3px solid #f88686;
			    font-size: 14px;
	    	}
	    	> dd {
	    		font-size: 13px;
	    		line-height: 28px;
	    	}
    	}
    }
}