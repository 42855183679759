

$dgFontColorBase: #333;
$dgFontColorHalf: #666;
$dgFontColorLight: #999;
$dgFontColorPitat: #390;
$dgFontColorInvert: #fff;
$dgFontColorHighlight: #f57900;
$dgFontColorAccent: #dd3e3e;
$dgFontColorAccentSale: #0070C0;

$dgFontSizeBase: 12;
$dgFontFamilyBase: 'ヒラギノ角ゴ ProN','Hiragino Kaku Gothic ProN','メイリオ',Meiryo,'ＭＳ Ｐゴシック','MS PGothic',sans-serif;
$dgFontLineheightBase: 20;



$dgLinkColorBase: #06c;
$dgLinkHoverColorBase: #06c;
$dgLinkVisitedColorBase: #969;
$dgLinkActiveColorBase: #06c;



$dgBoxColorBase: #fff;


$dgBoxWidthWide: 1216;

$dgBoxWidthBase: 960;

$dgBoxWidthMain: 715;
$dgBoxWidthSide: 225;

$dgBoxPaddingBase: 15;
$dgBoxMarginBase: 20;


$dgBoxTopBorderSize: 6;


$dgBoxRadiusBase: 5;

$dgBoxRadiusSmall: 3;


$dgBorderColorBase: #ccc;
$dgBorderWidthBase: 1;



$dgBgImageCross: url("/assets/base/images/background/bg_gray_cross.webp");

$top_contents_width : 906;
$top_side_width : 295;
