
@mixin funcOverflowHidden()
{
	overflow: hidden;
}

@mixin funcOverflow($param)
{
	overflow: $param;
}

@mixin funcFloat($vec)
{
	float: $vec;
}


@mixin funcMargin($top, $right, $bottom, $left)
{
	margin: ($top + px) ($right + px) ($bottom + px) ($left + px);
}

@mixin funcMarginLeft($size)
{
	margin-left: ($size + px);
}
@mixin funcMarginRight($size)
{
	margin-right: ($size + px);
}
@mixin funcMarginTop($size)
{
	margin-top: ($size + px);
}
@mixin funcMarginBottom($size)
{
	margin-bottom: ($size + px);
}
@mixin funcMarginAll($size)
{
	margin: ($size + px);
}
@mixin funcMarginVerHori($vertical, $horizontal)
{
	margin: ($vertical + px) ($horizontal + px);
}
@mixin funcMarginTopSideBtm($top, $side, $bottom)
{
	margin: ($top + px) ($side + px) ($bottom + px);
}
@mixin funcMarginAuto()
{
	margin: 0 auto;
}
@mixin funcMarginVerAuto($vertical)
{
	margin: ($vertical + px) auto;
}


@mixin funcMarginPcnt($top, $right, $bottom, $left){
	margin: percentage($top * 0.01) percentage($right * 0.01) percentage($bottom * 0.01) percentage($left * 0.01);
}

@mixin funcMarginPcntLeft($size)
{
	margin-left: percentage($size * 0.01);
}
@mixin funcMarginPcntRight($size)
{
	margin-right: percentage($size * 0.01);
}
@mixin funcMarginPcntTop($size)
{
	margin-top: percentage($size * 0.01);
}
@mixin funcMarginPcntBottom($size)
{
	margin-bottom: percentage($size * 0.01);
}
@mixin funcMarginPcntAll($size)
{
	margin: percentage($size * 0.01);
}
@mixin funcMarginPcntVerHori($vertical, $horizontal)
{
	margin: ($vertical * 0.01) ($horizontal * 0.01);
}
@mixin funcMarginPcntTopSideBtm($top, $side, $bottom)
{
	margin: percentage($top * 0.01) percentage($side * 0.01) percentage($bottom * 0.01);
}
@mixin funcMarginPcntVerAuto($vertical)
{
	margin: percentage($vertical * 0.01) auto;
}


@mixin funcPadding($top, $right, $bottom, $left)
{
	padding: ($top + px) ($right + px) ($bottom + px) ($left + px);
}

@mixin funcPaddingLeft($size)
{
	padding-left: ($size + px);
}
@mixin funcPaddingRight($size)
{
	padding-right: ($size + px);
}
@mixin funcPaddingTop($size)
{
	padding-top: ($size + px);
}
@mixin funcPaddingBottom($size)
{
	padding-bottom: ($size + px);
}
@mixin funcPaddingAll($size)
{
	padding: ($size + px);
}
@mixin funcPaddingVerHori($vertical, $horizontal)
{
	padding: ($vertical + px) ($horizontal + px);
}
@mixin funcPaddingTopSideBtm($top, $side, $bottom)
{
	padding: ($top + px) ($side + px) ($bottom + px);
}
@mixin funcPaddingTopLRBottom($top, $left_right, $bottom)
{
	padding: ($top + px) ($left_right + px) ($bottom + px);
}
@mixin funcPaddingTopBottom($size){
	@include funcPadding($size, 0, $size, 0);
}


@mixin funcPaddingPcnt($top, $right, $bottom, $left)
{
	padding: percentage($top * 0.01) percentage($right * 0.01) percentage($bottom * 0.01) percentage($left * 0.01);
}

@mixin funcPaddingPcntLeft($size)
{
	padding-left: percentage($size * 0.01);
}
@mixin funcPaddingPcntRight($size)
{
	padding-right: percentage($size * 0.01);
}
@mixin funcPaddingPcntTop($size)
{
	padding-top: percentage($size * 0.01);
}
@mixin funcPaddingPcntBottom($size)
{
	padding-bottom: percentage($size * 0.01);
}
@mixin funcPaddingPcntAll($size)
{
	padding: percentage($size * 0.01);
}


@mixin funcBackground($img)
{
	background: $img;
}
@mixin funcBackgroundNoRep($img, $pos_x, $pos_y)
{
	background-image: $img;
	background-position: $pos_x $pos_y;
	background-repeat: no-repeat;
}
@mixin funcBackgroundRepX($img, $pos_x, $pos_y)
{
	background: $img $pos_x $pos_y repeat-x;
}
@mixin funcBackgroundRepY($img, $pos_x, $pos_y)
{
	background: $img $pos_x $pos_y repeat-y;
}
@mixin funcBackgroundPosition($pos)
{
	background-position: $pos;
}
@mixin funcBackgroundPositionXY($pos_x, $pos_y) {
	background-position: $pos_x $pos_y;
}
@mixin funcBackgroundNoRepeat() {
	background-repeat: no-repeat;
}
@mixin funcBackgroundImg($img)
{
	background-image: $img;
}

@mixin funcBkColor($color)
{
	background-color: $color;
}

@mixin funcBkSize($sizex, $sizey)
{
	background-size: ($sizex + px) ($sizey + px);
	-webkit-background-size: ($sizex + px) ($sizey + px);
}
@mixin funcBkSizePcntVer($size)
{
	background-size: percentage($size * 0.01) auto;
		-webkit-background-size: percentage($size * 0.01) auto;
}
@mixin funcBkSizePcntHori($size)
{
	background-size: auto percentage($size * 0.01);
		-webkit-background-size: auto percentage($size * 0.01);
}
@mixin funcBkSizeWidthAuto($size)
{
	background-size: ($size + px) auto;
		-webkit-background-size: ($size + px) auto;
}
@mixin funcBkSizeAutoHeight($size)
{
	background-size: auto ($size + px);
		-webkit-background-size: auto ($size + px);
}


@mixin funcWidth($size)
{
	width: ($size + px);
}
@mixin funcMinWidth($size)
{
	min-width: ($size + px);
}
@mixin funcMaxWidth($size)
{
	max-width: ($size + px);
}
@mixin funcWidthPcnt($size)
{
	width: percentage($size * 0.01);
}


@mixin funcHeight($size)
{
	height: ($size + px);
}
@mixin funcMinHeight($size)
{
	min-height: ($size + px);
}
@mixin funcMaxHeight($size)
{
	max-height: ($size + px);
}
@mixin funcHeightPcnt($size)
{
	height: percentage($size * 0.01);
}



@mixin funcBorder($size, $style, $color)
{
	border: ($size + px) $style $color;
}

@mixin funcBorderTop($size, $style, $color)
{
	border-top: ($size + px) $style $color;
}

@mixin funcBorderLeft($size, $style, $color)
{
	border-left: ($size + px) $style $color;
}

@mixin funcBorderRight($size, $style, $color)
{
	border-right: ($size + px) $style $color;
}

@mixin funcBorderBottom($size, $style, $color)
{
	border-bottom: ($size + px) $style $color;
}

@mixin funcBorderNone()
{
	border: none;
}

@mixin funcBorderTopNone()
{
	border-top: none;
}

@mixin funcBorderRightNone()
{
	border-right: none;
}

@mixin funcBorderBottomNone()
{
	border-bottom: none;
}

@mixin funcBorderLeftNone()
{
	border-left: none;
}

@mixin funcBorderCollapse($style)
{
	border-collapse: $style;
}

@mixin funcEmptyCells($style)
{
	empty-cells: $style;
}

@mixin funcBorderRadius($size)
{
	-moz-border-radius: ($size + px);
	-webkit-border-radius: ($size + px);
	border-radius: ($size + px);
}

@mixin funcBorderRadiusLeftTop($size)
{
	border-top-left-radius: ($size + px);
	-webkit-border-top-left-radius: ($size + px);
	-moz-border-radius-topleft: ($size + px);
}

@mixin funcBorderRadiusRightTop($size)
{
	border-top-right-radius: ($size + px);
	-webkit-border-top-right-radius: ($size + px);
	-moz-border-radius-topright: ($size + px);
}

@mixin funcBorderRadiusLeftBottom($size)
{
	border-bottom-left-radius: ($size + px);
	-webkit-border-bottom-left-radius: ($size + px);
	-moz-border-radius-bottomleft: ($size + px);
}

@mixin funcBorderRadiusRightBottom($size)
{
	border-bottom-right-radius: ($size + px);
	-webkit-border-bottom-right-radius: ($size + px);
	-moz-border-radius-bottomright: ($size + px);
}

@mixin funcBorderRadiusTop($size)
{
	border-top-left-radius: ($size + px);
	border-top-right-radius: ($size + px);
	-webkit-border-top-left-radius: ($size + px);
	-webkit-border-top-right-radius: ($size + px);
	-moz-border-radius-topleft: ($size + px);
	-moz-border-radius-topright: ($size + px);
}

@mixin funcBorderRadiusBottom($size)
{
	border-bottom-left-radius: ($size + px);
	border-bottom-right-radius: ($size + px);
	-webkit-border-bottom-left-radius: ($size + px);
	-webkit-border-bottom-right-radius: ($size + px);
	-moz-border-radius-bottomleft: ($size + px);
	-moz-border-radius-bottomright: ($size + px);
}

@mixin funcBorderRadiusLeft($size)
{
	border-top-left-radius: ($size + px);
	border-bottom-left-radius: ($size + px);
	-webkit-border-top-left-radius: ($size + px);
	-webkit-border-bottom-left-radius: ($size + px);
	-moz-border-radius-topleft: ($size + px);
	-moz-border-radius-bottomleft: ($size + px);
}

@mixin funcBorderRadiusRTopLBottom($size)
{
	border-top-right-radius: ($size + px);
	border-bottom-left-radius: ($size + px);
	-webkit-border-top-right-radius: ($size + px);
	-webkit-border-bottom-left-radius: ($size + px);
	-moz-border-radius-topright: ($size + px);
	-moz-border-radius-bottomleft: ($size + px);
}

@mixin funcBorderRadiusLTopRBottom($size)
{
	border-top-left-radius: ($size + px);
	border-bottom-right-radius: ($size + px);
	-webkit-border-top-left-radius: ($size + px);
	-webkit-border-bottom-right-radius: ($size + px);
	-moz-border-radius-topleft: ($size + px);
	-moz-border-radius-bottomright: ($size + px);
}


@mixin funcBoxShadowBottom($size, $color) {
	-webkit-box-shadow: 0 ($size + px) 0 0 $color;
	-moz-box-shadow: 0 ($size + px) 0 0 $color;
	box-shadow: 0 ($size + px) 0 0 $color;
}



@mixin funcFontColor($color)
{
	color: $color;
}

@mixin funcFont($size)
{
	font-size: ($size + px);
}

@mixin funcLineHeight($size)
{
	line-height: ($size + px);
}

@mixin funcFontFamily($family)
{
	font-family: $family;
}

@mixin funcFontWeight($size)
{
	font-weight: $size;
}

@mixin funcTextDecoration($style)
{
	text-decoration: $style;
}

@mixin funcLetterSpacing($size)
{
	letter-spacing: ($size + px);
}

@mixin funcTextAlign($layout)
{
	text-align: $layout;
}

@mixin funcTextIndent($size)
{
	text-indent: ($size + px);
}

@mixin funcTextIndentPcnt($size)
{
	text-indent: ($size + '%');
}

@mixin funcVerticalAlign($style)
{
	vertical-align: $style;
}


@mixin funcCursorPointer()
{
	cursor: pointer;
}



@mixin funcDisplay($style)
{
	display: $style;
}
@mixin funcOutline($style)
{
	outline: $style;
}


@mixin funcPosition($style)
{
	position: $style;
}
@mixin funcPositionTop($size)
{
	top: ($size + px);
}
@mixin funcPositionBottom($size)
{
	bottom: ($size + px);
}
@mixin funcPositionLeft($size)
{
	left: ($size + px);
}

@mixin funcPositionRight($size)
{
	right: ($size + px);
}
@mixin funcPositionTopPcnt($size)
{
	top: percentage($size * 0.01);
}
@mixin funcPositionBottomPcnt($size)
{
	bottom: percentage($size * 0.01);
}
@mixin funcPositionLeftPcnt($size)
{
	left: percentage($size * 0.01);
}
@mixin funcPositionRightPcnt($size)
{
	right: percentage($size * 0.01);
}
@mixin funcZIndex($index)
{
	z-index: (1 * $index);
}


@mixin funcOpacity($trans_num) {
	opacity: ($trans_num);
}


@mixin funcAppearance($style) {
	-webkit-appearance: $style;
	-moz-appearance: $style;
	appearance: $style;
}


@mixin funcBoxSizingBorderBox() {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

@mixin funcWordBreak(){
	word-wrap : break-word;
	overflow-wrap : break-word;
	word-break : break-all;
}

@mixin funcListStyleType($type){
	list-style-type:$type;
}
