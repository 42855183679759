
@mixin mlRelatedLink()
{
	@include mgSideContentGeneralBox( shop);
	@include mgListFixWidth( 193 - 20);

	> h4 {
		@include funcMarginTopSideBtm( 0, 10, 15);
		@include funcPaddingTopSideBtm( 12, 2, 10);
		@include funcBorderBottom( $dgBorderWidthBase, solid, $dgFontColorHalf);
	}

	> ul {
		@include funcPaddingTopSideBtm( 0, 15, 5);
		@include mgListIcon(li, 15, 20, url(/assets/base/images/arrow/icn_pitat_circle_decre.webp), left, top);

		> li {
			@include funcMarginBottom( 10);
		}
	}
}

.mlShopSearch {
	@include mgContentBottomCircleBox( $dgBoxTopBorderSize, shop, top);

	> div.search_ttl {
		@include funcPaddingAll( 5);
		@include funcBackgroundRepX(url("/assets/shop/images/bg_ttl_shop_search.webp"), left, top);
	}

	> div.search_area {
		@include funcMargin(0, 5, 5, 5);
		@include funcPaddingVerHori( 10, 7);
		@include funcBorderTop( $dgBorderWidthBase, solid, $dgFontColorHalf);
		@include funcBackground($dgBgImageCross);

		> form {

			> input.txt_area {
				@include funcPaddingAll( 4);
				@include funcWidth(135);
				@include funcVerticalAlign(middle);
			}

			> input.btn_area {
				@include funcVerticalAlign(middle);
			}
		}
	}
}

.mlNewShop {
	@include mgSideContentSideSpaceBox( shop);
	@include mgBoxPhotoWithInfo();

	> h3 {
		@include funcMarginTop( 11);
	}

	> ul {

		> li {

			> div.detail_box {
				@include mgPitatLineHeight();

				> p.open_date {
					@include mgSmallFont();
				}
			}
		}
	}
}

.mlShopStaff {
	@include mgSideContentSideSpaceBox( shop);
	@include mgBoxPhotoWithInfo();

	> ul {

		> li {

			> div.detail_box {
				@include mgPitatLineHeight();
			}
		}
	}

	> h3 {
		@include funcMarginTop( 10);
		border-bottom: 2px dotted #ccc;
	}

	> div.shop_message {
		@include funcMarginTop( 8);

		> img {
			@include funcTextAlign(center);
		}

		> p.message {
			@include funcMarginTop($dgBoxPaddingBase - (($dgFontLineheightBase - $dgFontSizeBase) / 2));
			@include mgPitatLineHeight();
		}
	}

	> hr.separate {
		@include funcMarginTop($dgBoxPaddingBase - (($dgFontLineheightBase - $dgFontSizeBase) / 2));
		@include funcHeight(0);
		@include funcBorderNone();
		@include funcBorderTop( 1, dotted, $dgBorderColorBase);
	}

	> ul {

		> li {

			> div.detail_box {

				> span.staff_position {
					@include mgSmallFont();
				}

				> a.staff_name {
					@include mgFontBoldWithSize($dgFontSizeBase + 2);
					@include funcLineHeight($dgFontSizeBase + 2 + 5);
				}
			}
		}
	}
}


.mlInquiry {
	@include funcPaddingBottom( $dgBoxPaddingBase - 8);
	@include mgSideContentGeneralBox( common);

	> h4 {
		@include funcMarginVerHori( 10, 6);
	}

	> div.shop_photo {
		@include funcMarginAuto();
		@include funcMarginBottom( 8);
		@include funcWidth(212);
		@include funcHeight(159);
		@include funcBorderRadius($dgBoxRadiusSmall);
	}

	> div.shop_name {
		@include funcMarginBottom( 10);
		@include funcPaddingVerHori( 0, $dgBoxPaddingBase - 3);
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcLineHeight($dgFontLineheightBase - 2);

		> a {

			> span {
				@include funcFont($dgFontSizeBase - 1);
			}
		}
		> span.recruiter {
			@include funcFontWeight(normal);
			@include funcFont($dgFontSizeBase);
		}
	}

	> div.shop_tel {
		@include funcMarginBottom( 10);
		@include funcPaddingVerHori( 0, $dgBoxPaddingBase - 3);

		> div.freecall_ttl {
			@include funcMarginBottom( 5);
			@include funcLineHeight(23);
			@include funcFont($dgFontSizeBase - 2);
			@include mgVerticalImage();
		}

		> div.freecall_no {
			@include funcMarginBottom( 10);
			@include funcPaddingLeft( 22);
			@include mgFontBoldWithSize($dgFontSizeBase + 4);
			@include funcFontColor($dgFontColorAccent);
			@include funcLineHeight(23);
			@include funcBackgroundNoRep(url("/assets/base/images/background/bg_icn_free_call.webp"), left, top);

			> span {
				@include funcVerticalAlign(middle);
			}
		}

		> div.tel_no {
			@include funcMarginBottom( 10);
			@include mgFontBoldWithSize($dgFontSizeBase + 4);
			@include funcLineHeight(20);
		}

		> div.ip_phone {
			@include funcFont($dgFontSizeBase - 2);
			@include funcLineHeight($dgFontSizeBase - 2);
			@include mgVerticalImage();
		}

	}

	> ul {
		@include funcPaddingVerHori( 0, $dgBoxPaddingBase - 2);
		@include funcWidth(198);

		> li {
			@include funcMarginBottom( 8);
		}
	}

	> div.spacer {
		@include funcBorderTop(1,dotted, #cccccc);
	}
}
@mixin mlNaviLibrary()
{
	@include funcPaddingBottom( $dgBoxPaddingBase - 5);
	@include mgSideContentGeneralBox( common);

	> div.title {
		@include funcMarginVerHori( $dgBoxPaddingBase, 0);
		@include funcTextAlign(center);
	}

	> ul {
		@include funcMarginAuto();
		@include funcWidth(209);

		> li {
			@include funcMarginBottom( 5);
		}
	}
}

.mlSidePitat {

	> div.navi_library {
		@include mlNaviLibrary();
	}

	> div.banner_box {
		@include mgBlockSpace();
	}

	> div.related_link {
		@include mlRelatedLink();
	}
}

div.banner_box > .ban_a > .ban_space {
	@include funcMarginBottom( 15);
}


.mlCompanyInfo {
	@include funcPaddingBottom( $dgBoxPaddingBase - 8);
	@include mgSideContentGeneralBox( common);

	> h4 {
		@include funcMarginVerHori( 10, 6);
	}

	> div {
		@include funcBorderTop(2,dotted,#ccc);
		@include funcMarginAll(2);

		> ul {
			@include funcBackground($dgBgImageCross);
			@include funcPaddingTop(10);
			@include funcPaddingLeft(5);
			@include funcPaddingBottom(10);

			> li{
				@include funcLineHeight(20);

				> dl {
					> dd {
						@include funcMarginTop(5);
						@include funcMarginBottom(5);
					}
				}
			}
		}
	}
}
.mlFcRecruitSideBanner{
	@include mlRelatedLink();
	> div {

		> ul{
			> li{
				@include funcBorderBottom(2,dotted,#ccc);
				@include funcMarginVerHori(10,13);
				@include funcPaddingBottom(10);

				> dl{
					> dd{
						> a {
							@include funcLineHeight(18);
							> img{
								@include funcBorder(1,solid,#ccc);
							}
						}
					}
				}
			}
		}
	}
}

.mlShopAllBukkenSearch{
	@include mgSideContentGeneralBox( shop);
	>div.header{
		@include funcPaddingTop( 8);
		@include funcPaddingBottom( 10);

		> span.icon {
			@include funcPaddingLeft(15);
			@include funcWidth(28);
			@include funcHeight(28);
			> img {
				@include funcTextAlign(center);
			}
		}
		> span.title {
			@include funcPaddingLeft(15);
			@include funcFontWeight(bold);
			@include funcFont(19);
			@include funcLineHeight(25);
		}
	}
	>div.title{
		@include funcBackgroundRepX(url("/assets/shop/images/bg_ttl_shop_search.webp"), left, top);
		@include funcBorderBottom(1,dotted,#ccc);
		@include funcBorderTop(1,solid,#ccc);
		@include funcPaddingTop( 8);
		@include funcPaddingBottom( 10);
		> span.icon {
			@include funcFloat(left);
			@include funcPaddingLeft(15);
			@include funcPaddingRight(8);
			@include funcWidth(28);
			@include funcHeight(28);
			> img {
				@include funcTextAlign(center);
			}
		}
		> span.title {
			@include funcFontWeight(bold);
			@include funcFont(16);
			@include funcLineHeight(26);
		}
	}

	> div.search {
		@include funcPaddingTopLRBottom(10, 10, 0);

		> div.searchBox{
			@include funcPaddingBottom( 18);
			@include funcFontWeight(bold);
			@include funcFont(14);
			> div.rent{
				@include funcBackgroundNoRep(url("/assets/base/images/background/bkg_side_rent.webp"), left, center);
				@include funcPaddingLeft( 9);
				@include funcMarginLeft(3);
				@include funcMarginBottom( 8);
			}
			> div.sell{
				@include funcBackgroundNoRep(url("/assets/base/images/background/bkg_side_sell.webp"), left, center);
				@include funcPaddingLeft( 9);
				@include funcMarginLeft(3);
				@include funcMarginBottom( 8);
			}
			> ul.list{
				@include funcOverflowHidden();
				@include funcLineHeight(23);

				> li.chintai{
					@include funcPaddingRight( 0);
					letter-spacing: -1px;
				}

				> li{
					@include funcFloat(left);
					@include funcPaddingRight( 14);
					list-style-position:inside;
					list-style-type:disc;
				}
			}
		}
	}
	> div.search.shop{
		@include funcOverflowHidden();
		@include funcMarginLeft(10);
		@include funcMarginRight( 10);
		> form{
			> div.step{
				@include funcFontWeight(bold);
				@include funcHeight(19);
				> div.stepIcon{
					@include funcFloat(left);
					@include funcBkColor(#df962f);
					@include funcFontColor(#fff);
					@include funcBorderRadius(8);
					@include funcPaddingVerHori( 4, 5);
					@include funcMarginRight( 10);
				}
				> div.stepBun{
					@include funcFloat(left);
					@include funcLineHeight(20);
				}
			}
			> div{
				@include funcMarginBottom( 10);
				> select{
					@include funcWidth(190);
					@include funcHeight(28);
				}
			}
		}
	}
}

.mlShopEngine{
	@include mgSideContentGeneralBox( shop);

	>div.shopEngine{
		@include funcBackgroundNoRep(url("/assets/base/images/icon/bkg_side_search.webp"), 10px, center);
		@include funcBorderBottom(1,dotted,#ccc);
		@include funcPaddingTop( 8);
		@include funcPaddingBottom( 10);
		@include funcMarginBottom( 10);
		@include funcMarginLeft(3);
		@include funcPaddingLeft( 54);
		> span{

		}
		> span.tenmei{
			@include funcFontWeight(bold);
			@include funcFont(16);
			@include funcLineHeight(30);
		}
	}
	> div.search{
		@include funcOverflowHidden();
		@include funcMarginLeft(10);
		@include funcMarginRight( 10);
		> form{
			> div.step{
				@include funcFontWeight(bold);
				@include funcHeight(19);
				> div.stepIcon{
					@include funcFloat(left);
					@include funcBkColor(#df962f);
					@include funcFontColor(#fff);
					@include funcBorderRadius(8);
					@include funcPaddingVerHori( 4, 5);
					@include funcMarginRight( 10);
				}
				> div.stepBun{
					@include funcFloat(left);
					@include funcLineHeight(20);
				}
			}
			> div{
				@include funcMarginBottom( 10);
				> select{
					@include funcWidth(190);
					@include funcHeight(28);
				}
			}
		}
	}
}

.mlRentSaleSearchEngine{
	@include funcBorder(3, solid, #65b820);
	@include funcBorderRadius(8);
	@include funcMarginBottom( 20);
	>div.title{
		@include funcBkColor(#65b820);
		@include funcFontColor(#fff);
		@include funcFontWeight(bold);
		> div.mizuno{
			@include funcBackgroundNoRep(url("/assets/base/images/bkg_side_mizuno.webp"), left, center);
			@include funcPaddingLeft( 68);
			@include funcPaddingTop( 5);
			@include funcHeight(54);
			> span.tiiki{
				@include funcFont(17);
				@include funcLineHeight(30);
			}
		}
	}
	> div.search{
		@include funcBkColor(#fffaf0);

		@include funcPaddingTopLRBottom(18, 10, 0);

		> div.searchBox{
			@include funcPaddingBottom( 18);
			@include funcFontWeight(bold);
			@include funcFont(14);
			> div.rent{
				@include funcBackgroundNoRep(url("/assets/base/images/background/bkg_side_rent.webp"), left, center);
				@include funcPaddingLeft( 9);
				@include funcMarginLeft(3);
				@include funcMarginBottom( 8);
			}
			> div.sell{
				@include funcBackgroundNoRep(url("/assets/base/images/background/bkg_side_sell.webp"), left, center);
				@include funcPaddingLeft( 9);
				@include funcMarginLeft(3);
				@include funcMarginBottom( 8);
			}
			> ul.list{
				@include funcOverflowHidden();
				@include funcLineHeight(23);
				> li{
					@include funcFloat(left);
					@include funcPaddingRight( 14);
					list-style-position:inside;
					list-style-type:square;
				}
			}
		}
	}
	> div.buttonBox{
		@include funcPaddingBottom( 10);
		@include funcTextAlign(center);
		@include funcVerticalAlign(middle);
		@include funcBkColor(#fffaf0);
	}
}
