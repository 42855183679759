@mixin mlNewestCm()
{
	@include mgContentEdgeImgBoxTtl( h3, url("/assets/advertise/images/bg_ttl_new_cm.webp"));

	> div.cm_caption {
		@include mgBlockSpace();
	}

	> div.cm_gallery {
		@include mgBlockSpace();
		@include funcOverflowHidden();

		> h4{
			@include funcPaddingBottom(10);
			font-weight: bold;
            font-size: 16px;
		}

		> div {
			@include funcPosition(relative);
			@include funcWidth(350);
			height: 305px;
			@include mgListFreeWidth( $dgBoxPaddingBase);

			> img.cm_title {
				@include funcPosition(absolute);
				@include funcPositionBottom( 2);
				@include funcPositionLeft( 0);
			}

		}

		> div.cm_01 {
			@include funcFloat(left);
			@include mgPlayScreen();
		}

		> div.cm_02 {
			@include funcFloat(right);
			@include mgPlayScreen();
		}
	}
	> div.profile{
		@include funcPaddingBottom(20);
	}

	> div.cm_making{
		@include mgBlockSpace();

		> ul{
			@include funcOverflowHidden();
			> li{
				@include funcFloat(left);
				@include funcWidth(238);
				@include funcPaddingBottom(10);

				> div.img{
					@include funcTextAlign(center);
				}
				> div.comment{
					@include funcHeight(60);
					@include funcMarginLeft(5);
					@include funcMarginRight(5);

					@include mgRoundCornerBottom(5,1,solid,#F5F1B0);
					@include funcBkColor(#F5F1B0);

					> div{
						@include funcFontColor(#8E6F08);
						@include funcLineHeight(16);
						@include funcFontWeight(bold);
						@include funcMarginAll(5);
					}

				}

			}
		}

	}

	> div.cm_poster_position {
		@include funcPosition(relative);

		> .cm_shop_btn {
			> img.cm_shop_list {
				@include funcPosition(absolute);
				@include funcPositionBottom( 120);
				@include funcPositionRight( 60);
			}
		}

		> .sekkyaku_list_btn {
			> img.sekkyaku_list {
				@include funcPosition(absolute);
				@include funcPositionBottom( 50);
				@include funcPositionRight( 60);
			}
		}
	}

	> .radio_list {
		overflow: hidden;

		> .list_title {
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 10px;
			padding: 10px 0px;
			border-bottom: 1px dotted #999999;
		}

		> .list_space {
			padding-top: 20px;
		}

		> .radio_img {
			width: 225px;
			height: 204px;
			float: left;
		}

		> .img_space {
			margin: 0px 20px;
		}
	}
}

.mlCommercial {
	@include mgWrapTwoColumn( left, right);
	@include funcOverflowHidden();

	> div.main_column{
		> div.title {
			@include mgBlockSpace();
			@include mlTitleTopBgImg( h2, url("/assets/#{$theme}/images/bg_title.webp"));
		}

		> div.newest_cm {
			@include mlNewestCm();
		}
	}
}

.mlAuction{

	> div.main_top{
		@include funcBackgroundNoRep(url("/assets/advertise/images/bk_auction_top.webp"), left, top);
		@include funcWidth(960);
		@include funcHeight(200);
		@include funcMarginAuto();
		@include funcPosition(relative);
	}

	> div.video_box{
		@include funcBackgroundNoRep(url("/assets/advertise/images/bk_mv.webp"), left, top);
		@include funcWidth(960);
		@include funcHeight(310);
		@include funcMarginAuto();
		@include funcMarginBottom(20);
		@include funcMarginTop(20);

		> div.video_banner{
			@include funcWidth(575);
			@include funcMarginAuto();
			@include funcPosition(relative);
			@include funcPositionTop(23);

			> a {
				> span.play_screen {
					@include funcPosition(absolute);
					@include funcPositionTop(5);
					@include funcPositionLeft(5);
				}
			}
		}
	}

	> div.main_column{
		@include funcBackgroundNoRep(url("/assets/advertise/images/bk_auction.webp"), left, top);
		@include funcWidth(960);
		@include funcHeight(1953);
		@include funcMarginAuto();

		@include funcPosition(relative);

		> div{
			@include funcPosition(absolute);
		}

		> div.top_link{
			@include funcPositionTop(520);
			@include funcPositionLeft(400);
		}

		> div.bottom_link{
			@include funcPositionBottom(22);
			@include funcPositionLeft(400);
		}
	}
}
.mlCampaige{
	> div.main_column{
		@include funcWidth(960);
		@include funcHeight(2150);
		@include funcMarginAuto();
		@include funcPaddingBottom(40);
	}
}