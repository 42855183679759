
.mgHeader {

	> div {
		@include funcMarginAuto();
		@include funcHeight(80);
		@include funcOverflowHidden();
		@include funcPosition(relative);

		> div.header_logo {
			@include funcMarginTop( 12);
			@include funcWidth(500);
			@include funcFloat(left);
			@include funcOverflowHidden();

			> div.logo {
				@include funcWidth(160);
				@include funcFloat(left);
			}

			> p.info {
				@include funcFont($dgFontSizeBase);
				@include funcLineHeight($dgFontLineheightBase + 6);

				> span.emphasis {
					@include funcFontWeight(bold);
					@include funcFontColor($dgFontColorPitat);
				}
			}
			> h1 {
				@include funcLineHeight($dgFontLineheightBase + 6);
				> span.emphasis {
					@include funcFontWeight(bold);
					@include funcFontColor($dgFontColorPitat);
				}
			}
		}

		> div.header_navi {
			@include funcPosition(absolute);
			@include funcPositionTop( 35);
			@include funcPositionRight( 242);

			> ul {

				> li {
					@include funcMarginRight( 20);
					@include funcPaddingLeft( 10);
					@include funcFloat(left);
					@include funcFont($dgFontSizeBase);
					@include funcBackgroundNoRep(url("/assets/header/images/icn_arrow_header.webp"), left, 50%);
				}
			}
		}

		> div.personal_area {
			@include funcFloat(right);

			> ul {

				> li {
					@include funcFloat(left);
				}
			}
		}
	}

	> div.header_wide_wrapper {
		@include funcWidth($dgBoxWidthWide);
	}

	> div.header_wrapper {
		@include funcWidth($dgBoxWidthBase);
	}
}
