
.mlCityscape {
	@include funcPaddingBottom(173);
	@include funcBackgroundRepX(url("/assets/footer/images/bg_footer_city.webp"), left, bottom);
}

@mixin mlFooterNavi($side_space, $dl_width)
{
	@include funcPaddingVerHori( $dgBoxPaddingBase, $side_space);
	@include funcOverflowHidden();
	@include funcBorderRadiusTop( $dgBoxRadiusBase);
	@include funcBackground(url("/assets/footer/images/bg_footer_navi.webp"));

	> ul {
		@include funcMarginAuto();
		@include funcOverflowHidden();

		> li {
			@include funcFloat(left);

			> dl {
				@include funcWidth($dl_width);
				@include funcOverflowHidden();

				> dt {
					@include funcMarginBottom($dgBoxPaddingBase - (($dgFontLineheightBase + 5 - $dgFontSizeBase) / 2));

					> a {
						@include funcPaddingTopSideBtm( 11, 9, 10);
						@include funcLineHeight(16);
						@include funcDisplay(block);

						> span {
							@include funcFloat(right);
						}
					}
				}

				> dd {
					@include funcPaddingLeft( 9);
					@include funcLineHeight($dgFontLineheightBase + 5);

					> a {
						@include funcFontColor($dgFontColorBase);
						@include funcTextDecoration(underline);
					}

					> a:hover {
						@include funcFontColor($dgFontColorPitat);
					}
				}
			}

			> dl.rent_link {
				@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_rent.webp"), left, top);

				> dt {

					> a:hover{
						@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_rent_on.webp"), left, top);
					}
				}
			}

			> dl.buy_link {
				@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_buy.webp"), left, top);

				> dt {

					> a:hover{
						@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_buy_on.webp"), left, top);
					}
				}
			}

			> dl.sell_link {
				@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_sell.webp"), left, top);

				> dt {

					> a:hover{
						@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_sell_on.webp"), left, top);
					}
				}
			}

			> dl.shop_link {
				@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_shop.webp"), left, top);

				> dt {

					> a:hover{
						@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_shop_on.webp"), left, top);
					}
				}
			}

			> dl.pitat_link {
				@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_pitat.webp"), left, top);

				> dt {

					> a:hover{
						@include funcBackgroundNoRep(url("/assets/footer/images/bg_ttl_pitat_on.webp"), left, top);
					}
				}
			}
		}

		> li.separate_box {
			@include funcWidth(2);

			> hr.separate_vertical {
				@include funcMarginAll( 0);
				@include funcHeight(270);
				@include funcBorderNone();
				@include funcBackgroundRepY(url("/assets/footer/images/border_vertical.webp"), right, top);
			}
		}
	}
}

@mixin mlFooterBana($size)
{
	@include mgBlockSpace();
	@include funcOverflowHidden();
	@include funcBorderRadiusBottom( $dgBoxRadiusBase);
	@include funcBackground(url("/assets/footer/images/bg_bana_link.webp"));

	> ul {
		@include funcPadding($dgBoxPaddingBase, 0, $dgBoxPaddingBase, $size);
		@include funcOverflowHidden();
		@include funcBackgroundNoRep(url("/assets/footer/images/bg_link_shadow.webp"), center, top);

		> li {
			@include funcMarginRight( $size);
			@include funcFloat(left);
		}
	}
}

@mixin mlSeparateHorizontal()
{
	@include funcMarginAll( 0);
	@include funcHeight(0);
	@include funcBorderNone();
	@include funcBorderTop( 1, solid, #699f29);
	@include funcBorderBottom( 1, solid, #d7e9bb);
}

@mixin mlAboutPitat()
{
	@include funcPaddingVerHori( $dgBoxPaddingBase, 0);
	@include funcFontColor($dgFontColorInvert);
	@include mgPitatLineHeight();

	> div.pitat_ttl {
		@include funcMarginBottom( $dgBoxPaddingBase);
		@include funcFont($dgFontSizeBase + 4);
		@include funcLineHeight(21);
	}
}

@mixin mlFooterSearchNavi($width)
{
	@include funcMarginVerHori( $dgBoxMarginBase, 0);
	@include funcOverflowHidden();

	> div {



		> div.search_ttl {
			@include funcMarginBottom( 10);
			@include funcOverflowHidden();

			@include mgVerticalImage();

			> span {
				@include funcFontColor(white);
				@include funcFont(16);
			}

			> ul {
				@include funcOverflowHidden();
				@include funcFloat(right);

				> li {
					@include funcMarginLeft(10);
					@include funcFloat(left);
				}
			}
		}

		> div.link_area {
			@include funcPaddingAll( $dgBoxPaddingBase);
			@include funcBorderTop( $dgBorderWidthBase, solid, #568d1f);
			@include funcBorderLeft( $dgBorderWidthBase, solid, #568d1f);
			@include funcBorderBottom( $dgBorderWidthBase, solid, #abcd75);
			@include funcBorderRadius($dgBoxRadiusBase);
			@include funcBkColor(#699f29);

			> ul {
				@include funcOverflowHidden();

				> li {
					@include funcMarginRight( 10);
					@include funcFloat(left);
					@include funcWidth(50);

					> a {
						@include funcFontColor($dgFontColorInvert);
						@include funcLineHeight(25);
					}
				}
			}
		}
	}
}

@mixin mlFooterBottom()
{
	@include funcPaddingVerHori( $dgBoxPaddingBase, 0);
	@include funcOverflowHidden();
	@include funcBkColor(#588f22);

	> div.attention {
		@include funcFontColor($dgFontColorInvert);
		@include funcPaddingBottom(10);
	}

	> div.footer_wrapper {

		> ul {
			@include funcOverflowHidden();
			@include funcFloat(left);

			> li {
				@include funcFloat(left);
				@include funcLineHeight(22);
				@include funcMarginRight( $dgBoxMarginBase);

				> * {
					@include funcFontColor($dgFontColorInvert);
				}
			}
		}

		> div.copyright {
			@include funcLineHeight(22);
			@include funcFloat(right);
			@include funcFontColor($dgFontColorInvert);
		}
	}
}

@mixin mlFooterWrapper($width)
{
	@include funcMarginAuto();
	@include funcWidth($width);
}

.mlFooter {
	@include funcPaddingTop( $dgBoxMarginBase);
	@include funcBkColor(#83b633);

	> div {

		> div.footer_wrapper {

			> hr.separate_horizontal {
				@include mlSeparateHorizontal();
			}

			> div.about_pitat {
				@include mlAboutPitat();
			}
		}
	}

	> div.footer_wide_main {

		> div.attention {
			@include mlFooterWrapper($dgBoxWidthWide);
		}

		> div.footer_wrapper {
			@include mlFooterWrapper($dgBoxWidthWide);
			@include funcMinWidth($dgBoxWidthBase);

			> div.footer_navi {
				@include mlFooterNavi(16, 216);

				> ul {

					> li.separate_box {
						@include funcPaddingVerHori( 0, 10);
					}
				}
			}

			> div.footer_bana {
				@include mlFooterBana(54);
			}

			> div.footer_search_navi {
				@include mlFooterSearchNavi($dgBoxWidthWide);
			}
		}
	}

	> div.footer_main {

		> div.attention {
			@include mlFooterWrapper($dgBoxWidthBase);
		}

		> div.footer_wrapper {
			@include mlFooterWrapper($dgBoxWidthBase);

			> div.footer_navi {
				@include mlFooterNavi(10, 186);
			}

			> div.footer_bana {
				@include mlFooterBana(13);
			}

			> div.footer_search_navi {
				@include mlFooterSearchNavi($dgBoxWidthBase);
			}
		}
	}

	> div.footer_bottom {
		@include mlFooterBottom();
	}
}
