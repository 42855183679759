
.mlErrorPage{
	@include funcWidth(780);
	margin: 60px auto;
	
	> div.inner_box {
		@include funcOverflowHidden();
		@include funcPaddingAll(20);
		@include funcBorder(3, solid, #cccccc);
		
		> div.image_area{
			@include funcFloat(left);
			@include funcWidth(124);
		}
		
		> div.text_area {
			@include funcFloat(right);
			@include funcWidth(550);
			@include funcPadding(30, 20, 30, 20);
			@include funcBackground(#dddddd);
			@include funcFontColor(#777777);
			@include funcFont(14);
		}
	}
	
	> div.button_box{
		@include funcMargin(20,0,0,0);
		@include funcTextAlign(center);
	}
}
