@charset "utf-8";

@import "./_error_define.scss";

@import "../base/_pitat_function.scss";
@import "../base/_pitat_define.scss";

@import "../base/_reset.scss";
@import "../base/component/_pitat_reset.scss";

@import "../base/_pitat_base.scss";
@import "../base/component/_breadcrumb.scss";
@import "../base/component/_descript.scss";
@import "../base/component/_footer.scss";
@import "../base/component/_global_navi.scss";
@import "../base/component/_header.scss";
@import "../base/component/_side.scss";
@import "../base/component/_address.scss";
@import "../base/component/_railroad.scss";
@import "../base/component/_search_list.scss";
@import "../base/component/_detail.scss";
@import "../base/component/_advertise.scss";
@import "_error_base.scss";
