
body {
	@include funcFont($dgFontSizeBase);
	@include funcFontFamily($dgFontFamilyBase);
	@include funcFontColor($dgFontColorBase);
}

textarea, input, a, img, select {
	@include funcOutline(none);
}


a:link {
	@include funcFontColor($dgLinkColorBase);
	@include funcTextDecoration(none);
}

a:visited {
	@include funcFontColor($dgLinkVisitedColorBase);
	@include funcTextDecoration(none);
}

a:hover {
	@include funcFontColor($dgLinkHoverColorBase);
	@include funcTextDecoration(underline);
}

input[type="text"], textarea {
	@include funcPaddingAll( 3);
}

input[type="checkbox"] {
	@include funcMarginRight( 3);
	@include funcVerticalAlign(text-bottom);
}

.ui-menu-item {
	@include funcFont($dgFontSizeBase + 2);
	@include funcBkColor(#effbe5);
	
	> a:hover.ui-corner-all {
		@include funcFontColor($dgFontColorPitat);
	}
}