@mixin mlOpenHouse {
	@include mgBlockSpace();

	> div.contact_info {
		@include funcPaddingBottom( 10);
		@include mgFontBoldWithSize($dgFontSizeBase + 3);
		@include funcLineHeight(28);
		@include funcBorderBottom( $dgBorderWidthBase, dotted, $dgBorderColorBase);

		> span.tel_no {
			@include funcFontColor($dgFontColorAccent);
		}
	}

	> div.date_info {
		@include funcPaddingVerHori( 10, 0);
		@include funcLineHeight($dgFontLineheightBase + 2);
		@include funcBorderBottom( $dgBorderWidthBase, dotted, $dgBorderColorBase);
		@include mgListFixWidth( 260);

		> ul {
			@include mgListDot(li, $dgFontLineheightBase + 2, 8px);

			> li {
				> span.open_date {
					@include funcFontColor($dgFontColorPitat);
					@include funcFontWeight(bold);
				}
				> span.open_sort {
					@include funcFontColor($dgFontColorAccent);
				}
			}
			> li.open_info {
				width: auto;
			}
		}

		> div.free_txt {
			@include funcPaddingLeft(8);
		}
	}
}
@mixin mlDetailTitle($bg_img)
{
	@include mgBlockSpace();

	> h4 {
		@include funcPaddingLeft( 35);
		@include funcMarginBottom( $dgBoxPaddingBase);
		@include mgFontBoldWithSize($dgFontSizeBase + 4);
		@include funcLineHeight(36);
		@include funcLetterSpacing(1);
		@include funcBackgroundRepX($bg_img, left, 50%);
		@include funcBackgroundPosition(bottom);

		> span.smaller {
			@include funcFont($dgFontSizeBase + 2);
			@include funcFontWeight(normal);
		}
	}
}

@mixin mlSimplified()
{
	@include mgBlockSpace();
	@include funcPaddingVerHori( 10, 0);
	@include funcBorderRadiusBottom( $dgBoxRadiusBase);
	@include funcBackground($dgBgImageCross);

	> div.simple_data_wrap_top {
		@include funcPaddingTop( 8);
		@include funcBackgroundNoRep(url("/assets/base/images/background/bg_box_shadow_top.webp"), center, top);

		> div.simple_data_wrap_btm {
			@include funcPaddingBottom( 8);
			@include funcBackgroundNoRep(url("/assets/base/images/background/bg_box_shadow_btm.webp"), center, bottom);

			> div.simple_data_content {
				@include funcMarginVerHori( 0, 10);
				@include funcPaddingVerHori( 7, $dgBoxPaddingBase);
				@include funcOverflowHidden();
				@include funcBackgroundRepY(url("/assets/base/images/background/bg_box_shadow_mid.webp"), center, top);

				> div.school_left{
					@include funcFloat(left);
				}

				> div.school_right{
					@include funcFloat(left);
					@include funcPaddingLeft( 15);
					@include funcMarginAll( 5);
					@include funcWidth(680);

					> span{
						@include funcLineHeight(18);
					}
					> span.large{
						@include funcFont(16);
						@include funcLineHeight(26);
						@include funcFontWeight(bold);
					}

				}
				> div.simple_data_above_box {
					@include funcMarginBottom( $dgBoxPaddingBase);
					@include funcOverflowHidden();
					@include funcBorderBottom( $dgBorderWidthBase, dotted, $dgBorderColorBase);

					> div.price_data {
						@include funcMarginBottom( 10);
						@include mgPriceValue($dgFontSizeBase * 2);
						@include funcFloat(left);

						> img {
							@include funcMarginBottom( 2);
						}

						> span.auction{
							@include funcLineHeight(20);
						}
					}

					> ul.personal_area {
						@include funcFloat(right);
						@include funcOverflowHidden();

						> li {
							@include funcMarginLeft(20);
							@include funcFloat(left);
							@include mgVerticalImage();

							> a {
								@include funcFontWeight(bold);
							}
						}
					}

				}

				> div.simple_data_bottom_box {
					@include mgAddressAddData(350, 110);

					> dl {
						@include funcWidth(215);
						@include funcFloat(right);
						@include funcOverflowHidden();
						@include funcBackgroundNoRep(url("/assets/base/images/background/bg_number_favorite.webp"), left, top);

						> dt {
							@include funcPaddingVerHori( 6, 10);
							@include funcFloat(left);
							@include funcLineHeight($dgFontLineheightBase - 3);
							@include funcFontColor($dgFontColorInvert);

							> span.large_txt {
								@include funcFont($dgFontSizeBase + 2);
								@include funcFontWeight(bold);
							}
						}

						> dd {
							@include funcPaddingVerHori( 0, 10);
							@include funcFontWeight(bold);
							@include funcFloat(right);
							@include funcLineHeight(46);

							> span {
								@include funcFont($dgFontSizeBase + 8);
								@include funcFontWeight(normal);
								@include funcFontColor($dgFontColorAccent);
							}
						}
					}


					> ul.upper {
						@include funcOverflowHidden();
						@include mgMapRouteIcon(19);
						> li {
							@include funcFloat(left);
							@include funcMarginBottom( 10);
							> a {
								@include funcFontWeight(bold);
							}
						}
					}
					> ul.under {
						@include funcOverflowHidden();
						@include mgMapRouteIcon(19);
						> li{
							@include funcFloat(left);
							@include funcMarginBottom( 10);
						}
					}
				}
			}
		}
	}
}

@mixin mlDetailSelect()
{

	> ul.main_select {
		@include funcOverflowHidden();
		@include funcBackgroundRepX(url("/assets/base/images/background/bg_tab_line.webp"), left, 31px);

		> li {
			@include funcMarginLeft(10);
			@include funcFloat(left);

			> h3 {
				@include funcTextAlign(center);

				> span {
					@include funcPaddingTop( 13);
					@include funcWidth(105);
					@include funcHeight(34 - 13);
					@include funcDisplay(block);
					@include funcBackgroundNoRep(url("/assets/base/images/background/bg_tab_crnt.webp"), left, top);
				}

				> a {
					@include funcPaddingTop( 10);
					@include funcWidth(105);
					@include funcHeight(34 - 10);
					@include funcDisplay(block);
					@include funcBackgroundNoRep(url("/assets/base/images/background/bg_tab.webp"), left, top);
				}

				> a:hover {
					@include funcBackgroundNoRep(url("/assets/base/images/background/bg_tab_on.webp"), left, top);
				}
			}
		}
	}
}

.detail_content {
	@include funcPaddingAll( $dgBoxPaddingBase);

	> ul.note {
		@include funcLineHeight(20);
		@include funcPadding(0,0,0,10);
	}

	> ul.accent {
		@include funcFontColor(red);
	}
}
.shop_contact_content{
	@include funcPadding(0,$dgBoxPaddingBase,0,$dgBoxPaddingBase);
}

@mixin mlDetailBuildingData()
{
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_building_data.webp'));

	> table {
		@include mlDetailTable($dgFontLineheightBase - 2, 110, 190);

		> tbody {

			> tr {

				> th.top_space {
					@include funcPaddingTop( 11);
				}

				> th.accent {
					@include funcVerticalAlign(middle);
				}

				> td.price_data {
					@include funcPaddingTop( 11);
					@include mgPriceValue($dgFontSizeBase + 10);
				}

				> td.accent {
					@include funcVerticalAlign(middle);
				}

				> td.block_space {

					> img {
						@include funcMargin(5, 0, 10, 10);
						@include funcFloat(right);
					}
				}
			}
		}
	}


	> div.information {
		@include funcMarginLeft(10);
		@include funcPaddingTop(10);
		@include funcFont(11);
		@include funcLineHeight(13);
	}
}

@mixin mlDetailPhotoArea()
{
	@include funcOverflowHidden();
	@include funcMarginBottom( $dgBoxPaddingBase);

	> div.main_photo {
		@include funcMarginBottom( $dgBoxPaddingBase);
		@include mgPhotoImg();
		@include funcFloat(left);
	}

	> div.add_photo {
		@include funcFloat(right);

		> a {

			> img {
				@include mgPhotoImg();
				@include funcMarginBottom( $dgBoxPaddingBase);
			}

			> span {
				@include mgPhotoImg();
				@include funcMarginBottom( $dgBoxPaddingBase);
			}
		}

		> div.you_tube {
			@include funcPosition(relative);
			@include funcWidth(196);
			@include funcHeight(141);
			@include mgPhotoImg();

			> a.fancybox-media {

				> span.movie_start{
					@include funcPosition(absolute);
					@include funcPositionTop( 0);
					@include funcPositionLeft( 0);
				}

			}
		}
	}
}

@mixin mlDetailMiniPhotoArea()
{
	@include mgBlockSpace();
	@include funcPadding($dgBoxPaddingBase, 0, $dgBoxPaddingBase - 12, $dgBoxPaddingBase);
	@include funcOverflowHidden();
	@include mgRoundCorner($dgBoxRadiusBase, $dgBorderWidthBase, solid, $dgBorderColorBase);
	@include funcBackground($dgBgImageCross);

	> p {
		@include funcMarginBottom( $dgBoxPaddingBase);
		@include funcFontWeight(bold);
	}

	> div.photoImg {
		@include funcMargin(0, 12, 12, 0);
		@include funcFloat(left);
		@include mgPhotoImg();
	}
}


@mixin mlDetailRecommendPoint()
{
	@include funcFont($dgFontSizeBase + 2);
	@include funcFontWeight(bold);
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_recommend_point.webp'));

	> ul {
		@include mgListIconCircleM($dgBoxMarginBase + 4);
	}
}

@mixin mlDetailLifeInfo()
{
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_information.webp'));

	> table {
		@include mlDetailTable($dgFontLineheightBase - 2, 110, 190);
	}
}

@mixin mlDetailPersonInCarge()
{
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_from_agent.webp'));
	@include funcFont($dgFontSizeBase + 1);
	@include funcLineHeight($dgFontLineheightBase - 2);
}

@mixin mlDetailAreaMap()
{
	@include funcOverflowHidden();
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_area_map.webp'));

	> div {
		@include mgPhotoImg();

		> div.short {
			@include funcWidth(400);
			@include funcHeight(300);
		}

		> div.wide {
			@include funcWidth(800);
			@include funcHeight(300);
		}
	}

	> div.google_view {
		@include funcFloat(left);
	}

	> div.google_map {
		@include funcFloat(right);
	}
}

.mlDetailOutline {
	> div.open_house {
		@include mlOpenHouse();
	}

	> div.photo_area {
		@include mlDetailPhotoArea();
	}

	> div.mini_photo_area {
		@include mlDetailMiniPhotoArea();
	}



	> div.recommend_point {
		@include mlDetailRecommendPoint();
	}

	> div.building_data {
		@include mlDetailBuildingData();
	}

	> div.life_information {
		@include mlDetailLifeInfo();
	}

	> div.person_in_charge {
		@include mlDetailPersonInCarge();
	}

	> div.area_map {
		@include mlDetailAreaMap();
	}
}

div.mansion_review {
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_from_agent.webp'));

	> div.price_year_span {
	    display: flex;

	    > button {
            width: 50%;
            color: white;
            background-color: #668ad8;
            border: none;
	    }
	}

    > div.price_area {
        margin: 10px;

        > select {
            padding: 10px;
            min-width: 80px;
            height: 2.8em;
            cursor: pointer;
        }
    }
}


.mlShopContact {


	@include mgContentCircleBoxTtl( h4, common);
	@include funcTextAlign(center);
	@include funcBorderNone();

	> h4 {
		@include funcLineHeight(52);
		@include funcFont($dgFontSizeBase + 2);
		@include funcBorderRight( $dgBorderWidthBase, solid, $dgBorderColorBase);
		@include funcBorderLeft( $dgBorderWidthBase, solid, $dgBorderColorBase);
	}

	> div.shop_contact_main {
		@include funcPaddingTopSideBtm( 0, $dgBoxPaddingBase, $dgBoxPaddingBase);
		@include funcTextAlign(left);
		@include funcOverflowHidden();
		@include funcBorderRight( $dgBorderWidthBase, solid, $dgBorderColorBase);
		@include funcBorderLeft( $dgBorderWidthBase, solid, $dgBorderColorBase);

		> div.photo_box {
			@include funcPaddingTopSideBtm( $dgBoxPaddingBase, $dgBoxPaddingBase, 0);
			@include funcFloat(left);
			@include funcBorderTop( $dgBorderWidthBase, solid, $dgBorderColorBase);
		}

		> div.data_box {
			@include funcMarginLeft(180 + 1 *2 + $dgBoxPaddingBase);
			@include funcPaddingTopSideBtm( $dgBoxPaddingBase, $dgBoxPaddingBase, 0);
			@include funcBorderTop( $dgBorderWidthBase, solid, $dgBorderColorBase);

			> div.shop_name {
				@include funcMarginBottom( 10);
				@include funcFont($dgFontSizeBase + 4);
				@include funcFontWeight(bold);
			}

			> ul {
				@include funcMarginBottom( 10);
				@include funcOverflowHidden();

				> li {
					@include funcMarginRight( $dgBoxPaddingBase);
					@include funcFloat(left);
				}
			}

			> p.shop_detail {
				@include funcMarginBottom( 5);
				@include funcLineHeight($dgFontLineheightBase - 2);
			}

			> div.freecall_no {
				@include funcMarginBottom( 5);
				@include funcLineHeight(23);
				@include mgVerticalImage();

				> span {
					@include funcVerticalAlign(middle);
					@include funcPadding(0, 3, 0, 22);
					@include mgFontBoldWithSize($dgFontSizeBase + 6);
					@include funcFontColor($dgFontColorAccent);
					@include funcBackgroundNoRep(url("/assets/base/images/background/bg_icn_free_call.webp"), left, 50% #fefa95);
				}
			}

			> div.call_no {
				@include funcLineHeight(20);
				@include mgFontBoldWithSize($dgFontSizeBase + 6);
			}
		}
	}

	> ul.button_area {
		@include funcOverflowHidden();
		@include funcBorderTop( $dgBorderWidthBase, solid, $dgBorderColorBase);
		@include funcBackground(url("/assets/base/images/background/bg_gray_cross.webp"));

		> li {
			@include funcMargin($dgBoxPaddingBase, 0, $dgBoxPaddingBase, 29);

			@include funcFloat(left);
		}




	}
}

.box_map_ppup {
	@include funcWidth(120);
	@include funcPaddingAll( $dgBoxPaddingBase);
	@include funcPosition(relative);
	@include funcFont($dgFontSizeBase - 1);
	@include funcTextAlign(center);
	@include funcBkColor($dgBoxColorBase);

	> div.box_map_piyo {
		@include funcPosition(absolute);
		@include funcPositionRight( 0);
		@include funcPositionTop( 0);
		@include funcCursorPointer();
	}

	> p {

		> span.ppup_sort {
			@include funcDisplay(block);
			@include funcFont($dgFontSizeBase - 2);
			@include mgPitatLineHeight();
		}

		> span.ppup_name {
			@include funcPaddingLeft( $dgBoxPaddingBase);
			@include funcBackgroundNoRep(url("/assets/machipita/images/tmpMap/ic_circlearrow.webp"), left, top);
		}
	}
}

.environment_map {
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_environment.webp'));

	> div.map_area {
		@include funcWidth($dgBoxWidthMain - $dgBoxPaddingBase * 2 - $dgBorderWidthBase * 2);
		@include funcHeight(400);
		@include mgPhotoImg();
		@include funcPosition(relative);

		> div.box_attention {
			@include funcPosition(absolute);
			@include funcPositionTop( 10);
			@include funcPositionLeft( 1);
		}

		> div.map_detail {
			@include funcWidth($dgBoxWidthMain - $dgBoxPaddingBase * 2 - $dgBorderWidthBase * 2);
			@include funcHeight(400);
		}
	}
}

.recommend_shop {
	@include funcOverflowHidden();
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_recommend.webp'));

	> table {
		@include funcBorderCollapse(separate);

		> tbody {
			> tr {
				@include funcWordBreak();

				> td {
					@include funcPaddingTopSideBtm(10, $dgBoxPaddingBase, 0);
					@include funcWidth(198);
					@include funcBorderRight( $dgBorderWidthBase, solid, $dgBorderColorBase);
					@include funcEmptyCells(hide);
				}

				> td.photo_box {
					> a {
						> img {
							@include mgPhotoFrameImg(2);
						}
					}
				}

				> td.name_box {
					@include funcOverflowHidden();

					> div.icon_box {
						@include funcFloat(left);
						@include funcDisplay(flex);
						align-items: center;

						> a {
							@include funcFontWeight(bold);
							@include funcFont(14);
							@include funcMarginLeft(5);
						}
					}

					> h5 {
						@include funcMarginLeft(38);
						@include mgFontBoldWithSize($dgFontSizeBase + 2);
					}
				}

				> td.text_box {
					@include funcPaddingBottom( $dgBoxPaddingBase);
					@include funcLineHeight($dgFontLineheightBase - 2);
					@include funcTextAlign(justify);

					> a {
						@include funcFloat(right);
					}
				}
			}

			> tr.border_bottom {
				@include funcBorderBottom( $dgBorderWidthBase, solid, $dgBorderColorBase);
			}
		}
	}
}

.gyosei_information {
	@include funcOverflowHidden();
	@include mlDetailTitle(url('/assets/base/images/background/bg_ttl_from_agent.webp'));
	@include funcLineHeight(20);
	@include funcTextAlign(justify);

	> div.box_life_data {
		@include funcMarginBottom(20);

		> div.box_tbl_wrap {

			> h5 {
				@include funcFontWeight(bold);
				@include funcFontColor(#6eb92b);
				@include funcPadding(20, 0, 20 / 2, 20);
				@include funcBackgroundNoRep(url('/assets/machipita/images/tmpGyosei/icn_gyosei_h4.webp'), left, 60%);
			}

			> table {
				@include funcWidthPcnt(100);
				@include funcBorderTop(2, dotted, #e5f2d9);
				table-layout: fixed;

				> tbody {
					> tr {
						> th {
							@include funcWidthPcnt(36);
							@include funcPadding(12, 20, 12, 20);
							@include funcTextAlign(left);
							@include funcFontWeight(bold);
							@include funcBackground( #e5f2d9);
							@include funcBorderBottom(2, dotted, #fff);
						}
						> td {
							@include funcPadding(12, 20, 12, 20);
							@include funcBorderBottom(2, dotted, #e5f2d9);

							> a {
								@include funcWordBreak();
							}
						}
					}
				}
			}
		}
	}

	> div.gyosei_link_area {
		@include funcMarginTopSideBtm(20, 143, 30);
	}
}

.introductory_text {
	@include funcMarginBottom(20);
	@include funcLineHeight(17);
}

@mixin mlDetalBase()
{
	@include funcOverflowHidden();
	@include mgWrapOneColumn( h2, $theme);


	> div.simple_data {
		@include mlSimplified();
	}

	>div.heads_up{
		@include funcMarginBottom(20);
		@include funcPaddingAll(10);
		@include funcFontColor(red);
		@include funcLineHeight(16);
		@include funcBorder(1,solid,red);
	}

	> img{
		@include funcPaddingBottom(10);
	}

	> div.detail_tab {
		@include mgWrapTwoColumn( left, right);
		@include funcOverflowHidden();

		> div.main_column {
			@include mlDetailSelect();
			@include funcOverflowHidden();

			> div.related_link {
				@include funcWidth(690);
				@include funcPaddingAll(15);
				@include funcFloat(left);
			}

			> div.panorama{
				padding: 0px 15px 0px 15px;
				@include funcPosition(relative);
				@include funcMarginBottom(20);

				@include funcWidth(685);
				@include funcHeight(184);
				@include funcBackgroundNoRep(url("/assets/base/images/background/bg_360view.webp"), center, top);

				> a{
					@include funcPosition(absolute);
					@include funcPositionBottom( 20);
					@include funcPositionRight( 40);
				}
			}
		}

		> div.side_column {
			@include funcMarginTop( 30);
		}
	}
}
@mixin mlDetalBase2()
{
	@include funcOverflowHidden();

	@include funcMarginAuto();
	@include funcWidth($dgBoxWidthBase);

	> div.title{
		@include funcBackgroundRepX(url("/assets/#{$theme}/images/bg_title_#{$theme}.webp"), left, top);
		@include funcBackgroundPosition(top);

		> div{
			@include funcPaddingTop(6);
			@include funcOverflowHidden();

			> h2 {
				@include funcFont($dgFontSizeBase + 10);
				@include funcLineHeight(46);
				@include funcMinHeight(46);
				@include funcFontColor($dgFontColorInvert);
				@include funcBkColor($theme_color);
				@include funcPadding(0, 0, 0, 15);

				> span.small_txt {
					@include funcFont($dgFontSizeBase + 10 - 4);
				}
				.negotiation{
					margin-bottom: 12px;
        			margin-left: 10px;
				}
			}

		}
	}

	> div.simple_data {
		@include mlSimplified();
	}

	> img{
		@include funcPaddingBottom(10);
	}

	> div.detail_tab {
		@include mgWrapTwoColumn( left, right);
		@include funcOverflowHidden();

		> div.main_column {
			@include mlDetailSelect();
			@include funcOverflowHidden();

			> div.related_link {
				@include funcWidth(690);
				@include funcPaddingAll(15);
				@include funcFloat(left);
			}

			> div.panorama{
				padding: 0px 15px 0px 15px;
				@include funcPosition(relative);
				@include funcMarginBottom(20);

				@include funcWidth(685);
				@include funcHeight(184);
				@include funcBackgroundNoRep(url("/assets/base/images/background/bg_360view.webp"), center, top);

				> a{
					@include funcPosition(absolute);
					@include funcPositionBottom( 20);
					@include funcPositionRight( 40);
				}
			}
		}

		> div.side_column {
			@include funcMarginTop( 30);
		}

		> div.none_top{
			@include funcMarginTop( 0);
		}
	}
}

.mlDetail {
	@include mlDetalBase();
}

.mlDetailLong {
	@include mlDetalBase2();
}


.additional_data {
	@include mgSideContentGeneralBox( $theme);
	@include funcPaddingTopSideBtm( 0, 6, 6);

	> h4 {
		@include funcMarginTop(($dgBoxTopBorderSize / 2));
		@include funcPaddingTopSideBtm( 13, 0, 10);
		@include funcBorderBottom( $dgBorderWidthBase, solid, $dgFontColorHalf);
	}

	> ul {
		@include funcBackground($dgBgImageCross);

		> li {
			@include funcPaddingTopSideBtm( $dgBoxPaddingBase, $dgBoxPaddingBase - 6, $dgBoxPaddingBase);
			@include funcOverflowHidden();
			@include funcBorderBottom( 1, solid, #e8e8e8);

			> h5 {
				@include funcMarginBottom( 10);
				@include funcFont($dgFontSizeBase + 2);
				@include funcFontWeight(bold);
			}

			> div.building_photo {
				@include funcWidth(69);
				@include funcHeight(69);
				@include funcFloat(left);
				@include funcBorderRadius($dgBoxRadiusBase);
			}

			> div.building_info {
				@include funcMarginLeft(69 + 10);
				@include funcLineHeight($dgFontLineheightBase - 2);

				> div.building_price {
					@include funcMarginBottom( 5);
					@include mgPriceValue($dgFontSizeBase + 8);
				}
			}
		}
	}
}

.tmpDetailMap{
	@include funcMarginAll( 8);
	@include mlTitleTopBgImg( h2, url("/assets/#{$theme}/images/bg_title.webp"));
	padding: 6px 0px 0px 0px;

	> h2 {
		margin-left: 16px;
		@include funcPaddingBottom(8);
	}

	> div.area_map {
		@include funcOverflowHidden();
		@include funcPaddingBottom(8);

		@include mlDetailAreaMap();
	}
}